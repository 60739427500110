import { FC, useMemo } from 'react';
import { HeroCard } from '../../../shared/hero-card';
import { HeroVideo } from '../../../shared/hero-video';
import { FullWrapper, Content, CtaWrapper, CtaText, CtaListWrapper, SlideDisclaimer, StyledSpotHeader } from './campaign-item.styled';
import { contentSpotTypes } from '../../../../lib/api';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { CtaHandler } from '../../../shared/cta-handler';
import { useCtaHandler } from '../../../plus-sites-shared/hooks';
import { CenteredBlock } from '../../../structural-blocks/centered-block.component';
import { CSSProperties } from 'styled-components';
import { CoverImage } from '../../../shared/cover-image/cover-image.component';
import { getSpotOpacity } from '../../helpers';
import { SpotOverlay } from '../../../overlay/overlay.component';
import { hexToRGBA } from '../../../../utils/helpers/color.helpers';

export const FullScreenCampaignItem: FC<contentSpotTypes.FullScreenCampaignItem> = ({
    imageUrl,
    imageMobileUrl,
    videoUrl,
    videoMobileUrl,
    backgroundColor,
    slideCta,
    elements,
    slideFootnote,
    isAboveFold,
    carouselIndex,
    imagesOnly = false,
    overlay,
}) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    const { handleCtaClick } = useCtaHandler();

    const footNotePositionStyling: CSSProperties = useMemo(() => {
        let styles: CSSProperties = {};
        if (elements.length === 1) {
            const onlyElement = elements[0];
            if (onlyElement.elementPosition === 'Center') {
                styles = {
                    left: '50%',
                    transform: 'translateX(-50%)',
                };
            } else if (onlyElement.elementPosition === 'Right') {
                styles = {
                    right: '0',
                    textAlign: 'right',
                };
            }
        }
        return styles;
    }, [elements]);

    return (
        <FullWrapper
            backgroundColor={backgroundColor}
            onClick={() => (slideCta && slideCta.length > 0 ? handleCtaClick(slideCta[0]) : null)}
            isClickable={slideCta && slideCta.length > 0 ? true : false}
            imagesOnly={imagesOnly}
            verticalAlignment={elements.length > 0 ? elements[0].verticalAlignment : 'Center'}
            horizontalAlignment={elements.length > 0 ? elements[0].elementPosition : 'Center'}
        >
            {!isMobile ? videoUrl ? <HeroVideo videoUrl={videoUrl} /> : null : null}
            {isMobile ? videoMobileUrl ? <HeroVideo videoUrl={videoMobileUrl} /> : null : null}
            {isMobile && !videoMobileUrl ? videoUrl ? <HeroVideo videoUrl={videoUrl} /> : null : null}
            {!videoUrl && !videoMobileUrl ? (
                <CoverImage
                    src={isMobile && !!imageMobileUrl ? imageMobileUrl : imageUrl}
                    loading={isAboveFold && carouselIndex === 0 ? 'eager' : 'lazy'}
                    styles={overlay?.variant === 'Full width with gaussian blur' ? { filter: 'blur(5px)' } : {}}
                />
            ) : null}

            {overlay ? <SpotOverlay key={Math.random()} overlay={overlay} /> : null}

            {elements.length > 0 ? (
                <CenteredBlock maxWidth={1110}>
                    <Content
                        elementsCount={elements.length}
                        availablePosition={elements[0].elementPosition}
                        verticalAlignment={elements[0].verticalAlignment}
                    >
                        {elements.map((element, idx) => {
                            if (element.alias === 'campaignSetup') {
                                return (
                                    <CtaWrapper
                                        key={`fsc-element:description:${idx}`}
                                        textColor={element.textColor}
                                        style={{
                                            background:
                                                element.backgroundColor && element.backgroundColor.length > 0
                                                    ? hexToRGBA(element.backgroundColor, getSpotOpacity(element.backgroundOpacity))
                                                    : '',
                                            padding: '35px',
                                        }}
                                    >
                                        <StyledSpotHeader
                                            headerType={element.headerType}
                                            headerSize={element.headerSize}
                                            textColor={element.textColor}
                                            style={{ color: `#${element.textColor}` }}
                                        >
                                            {element.headlineText}
                                        </StyledSpotHeader>

                                        <CtaText>{element.description}</CtaText>

                                        {element.ctas ? (
                                            <CtaListWrapper>
                                                <CtaHandler ctas={element.ctas} />
                                            </CtaListWrapper>
                                        ) : null}
                                    </CtaWrapper>
                                );
                            }

                            if (element.alias === 'benefitsDetails' || element.alias === 'benefitsDetailsWithCTA') {
                                return (
                                    <HeroCard
                                        key={`fsc-element:agreement:${idx}`}
                                        tag={element.tagText}
                                        headline={element.headlineText}
                                        headerType={element.headerType}
                                        headerSize={element.headerSize}
                                        price={element.price}
                                        priceSubText={element.priceSubText}
                                        agreements={element.benefitsList.map((x) => x.titleText)}
                                        cta={element.alias === 'benefitsDetailsWithCTA' && element.cta ? element.cta[0] : undefined}
                                        backgroundColor={element.backgroundColor}
                                        backgroundOpacity={element.backgroundOpacity}
                                        textColor={element.textColor}
                                        horizontalLineColor={element.horizontalLineColor}
                                    />
                                );
                            }

                            return null;
                        })}

                        {slideFootnote && slideFootnote !== '' ? (
                            <SlideDisclaimer style={footNotePositionStyling} textColor={'#ff00ff00'}>
                                {slideFootnote}
                            </SlideDisclaimer>
                        ) : null}
                    </Content>
                </CenteredBlock>
            ) : null}
        </FullWrapper>
    );
};
