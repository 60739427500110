import styled from 'styled-components';

export const RibbonWrapper = styled.div<{ allowOverflow: boolean }>`
    &:not([data-keen-slider-disabled]) {
        align-content: flex-start;
        display: flex;
        ${({ allowOverflow }) => (allowOverflow ? '' : 'overflow: hidden')};
        position: relative;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
        width: 100%;

        .keen-slider__slide {
            position: relative;
            width: 100%;
            min-height: 100%;

            &--marketingcard {
                height: auto;
            }
        }

        &[data-keen-slider-reverse] {
            flex-direction: row-reverse;
        }

        &[data-keen-slider-v] {
            flex-wrap: wrap;
        }

        &[data-keen-slider-moves] * {
            pointer-events: none;
        }
    }
`;
