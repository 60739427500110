import { VehicleCampaign } from '../../lib/api/models/hessel-api/vehicle';
import { OwnershipMode, OwnershipTabWithDepricated } from '../../lib/view-models/vehicle';

export const filterCampaignsForProductList = (
    campaigns: Array<VehicleCampaign>,
    ownershipType: OwnershipMode,
    isCompanyCar?: boolean
): Array<VehicleCampaign> => {
    if (ownershipType === 'Køb') {
        if (isCompanyCar) {
            return campaigns.filter((campaign) => campaign.purchaseTypes.includes('SolutionTaxation'));
        }
        return campaigns.filter(
            (campaign) =>
                campaign.purchaseTypes.includes('Cash') || campaign.purchaseTypes.includes('Engros') || campaign.purchaseTypes.includes('Financing')
        );
    }

    return campaigns.filter(
        (campaign) =>
            campaign.purchaseTypes.includes('Hire') ||
            campaign.purchaseTypes.includes('PrivateLeasing') ||
            campaign.purchaseTypes.includes('OperationalLeasing') ||
            campaign.purchaseTypes.includes('FinancialLeasing')
    );
};

export const filterCampaignsForProductDetails = (
    campaigns: Array<VehicleCampaign>,
    selectedTab: OwnershipTabWithDepricated,
    isEngros?: boolean
): Array<VehicleCampaign> => {
    if (isEngros) {
        return campaigns.filter((campaign) => campaign.purchaseTypes.includes('Engros'));
    }

    switch (selectedTab) {
        case 'Car Finansiering':
            return campaigns.filter((campaign) => campaign.purchaseTypes.includes('Financing'));
        case 'Car HiRE':
            return campaigns.filter((campaign) => campaign.purchaseTypes.includes('Hire'));
        case 'Car Kontant':
            return campaigns.filter((campaign) => campaign.purchaseTypes.includes('Cash'));
        case 'Beskatningsgrundlag':
            return campaigns.filter((campaign) => campaign.purchaseTypes.includes('SolutionTaxation'));

        case 'Car Privat Leasing':
            return campaigns.filter((campaign) => campaign.purchaseTypes.includes('PrivateLeasing'));
        case 'Van Finansiel Leasing':
        case 'Finansiel Leasing':
            return campaigns.filter((campaign) => campaign.purchaseTypes.includes('FinancialLeasing'));
        case 'Van Operationel Leasing':
        case 'Operationel Leasing':
            return campaigns.filter((campaign) => campaign.purchaseTypes.includes('OperationalLeasing'));
        case 'Van Finansiering':
            return campaigns.filter((campaign) => campaign.purchaseTypes.includes('Financing'));
        case 'Van Kontant':
            return campaigns.filter((campaign) => campaign.purchaseTypes.includes('Cash'));
        default:
            return [];
    }
};
