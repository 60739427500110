import { useMemo, useState, VFC } from 'react';
import reactStringReplace from 'react-string-replace';
import { Bundle } from '../../../../lib/api/models/hessel-api';
import { BundleUSP, sharedTypes } from '../../../../lib/api/models/umbraco';
import { SixBySixBundleSpot } from '../../../../lib/api/models/umbraco/content-spot';
import { MarketingProductCard, ProductCard } from '../../../../lib/view-models/vehicle';
import { getFormattedValue, isNullOrEmpty } from '../../../../utils/helpers';
import { CenteredBlock } from '../../../structural-blocks/centered-block.component';
import { useUI } from '../../../ui';
import { VehicleProductCardCarousel } from '../../../vehicle/vehicle-product-card-carousel/vehicle-product-card-carousel.component';

import { VehicleProductListMarketingCard } from '../../../vehicle/vehicle-product-list/vehicle-product-list-marketingcard';
import { BundleDetailsModal } from '../bundle-details-modal';
import {
    StyledBundleSpot,
    StyledHeader,
    StyledDescription,
    FoundText,
    StyledResult,
    BundleCardsWrapper,
    StyledCarouselWrapper,
    StyleBundleSpotHeader,
} from './bundle-spot.styled';
import { Abbreviations } from '../../../../constants/units-and-abbreviations';
import { VehicleProductListCard } from '../../../vehicle/vehicle-product-list/vehicle-product-list-card';

export type BundleSpotProps = {
    content: SixBySixBundleSpot;
    pageId: string;
    bundleUSPs: BundleUSP[];
    bundles: Bundle[];
    bundleCards: ProductCard[];
    marketingProductCards: MarketingProductCard[];
    bundleDialogHeader: string;
    bundleDialogStartupFrom: string;
    bundleDialogOverviewOfferHeader: string;
    bundleDialogPaymentPlanHeader: string;
    bundleDialogSubmitButton: string;
    bundleDialogReceiptHeader: string;
    bundleDialogReceiptDescription: string;
    bundleDialogReceiptSubmitButton?: sharedTypes.MixedCta;
};

export const BundleSpot: VFC<BundleSpotProps> = ({
    content,
    pageId,
    bundles,
    bundleDialogHeader,
    bundleDialogStartupFrom,
    bundleDialogOverviewOfferHeader,
    bundleDialogPaymentPlanHeader,
    bundleDialogSubmitButton,
    bundleCards,
    marketingProductCards,
    bundleDialogReceiptHeader,
    bundleDialogReceiptDescription,
    bundleDialogReceiptSubmitButton,
}) => {
    const [showBundleDetailsDialog, setShowBundleDetailsDialog] = useState(false);
    const [selectedBundle, setSelectedBundle] = useState<Bundle | null>(null);

    const allProductCards: ProductCard[] = useMemo(() => {
        const allCards = [...(bundleCards ?? [])];
        const listLength = bundleCards ? bundleCards.length : 0;
        const filteredMarketingCards = marketingProductCards.filter((x) => x.index <= listLength);
        for (const card of filteredMarketingCards) {
            allCards.splice(card.index - 1, 0, card);
        }
        return allCards;
    }, [bundleCards, marketingProductCards]);

    const uiHandler = useUI();

    const selectBundle = (bundleId: string) => {
        const bundleToSelect = bundles.find((x) => x.id === bundleId);
        if (bundleToSelect) {
            setSelectedBundle(bundleToSelect);
        }
    };

    const fullDuration = useMemo(() => {
        if (selectedBundle) {
            return selectedBundle.durationVehicle1 + selectedBundle.durationVehicle2;
        }
        return 0;
    }, [selectedBundle]);

    const getBundleCards = () => {
        return allProductCards.map((product, index) => {
            if (product.type === 'detailed') {
                return (
                    <div key={`${product.id}-${index}`} className="keen-slider__slide">
                        <VehicleProductListCard
                            productCard={product}
                            onCardClick={() => {
                                uiHandler.removeScroll();
                                selectBundle(product.id);
                                setShowBundleDetailsDialog(true);
                            }}
                            allowCarousel={false}
                            isAboveFold={content.isAboveFold}
                        />
                    </div>
                );
            }
            return null;
        });
    };

    const getTexts = () => {
        return (
            <>
                {isNullOrEmpty(content.headerSize) ? (
                    <StyledHeader as={content.headerType}>{content.header}</StyledHeader>
                ) : (
                    <StyleBundleSpotHeader headerType={content.headerType} headerSize={content.headerSize}>
                        {content.header}
                    </StyleBundleSpotHeader>
                )}

                <StyledDescription>{content.description}</StyledDescription>
                <FoundText>
                    {reactStringReplace(content.foundBundlesText, '{{amount}}', (_, idx) => (
                        <StyledResult key={idx}>{bundles.length}</StyledResult>
                    ))}
                </FoundText>
            </>
        );
    };

    if (!bundleCards && !content.carsInBundle) return null;

    return (
        <StyledBundleSpot id={content.scrollAnchorId}>
            {content.displayType === 'Ribbon / Carousel' ? (
                <>
                    <CenteredBlock>{getTexts()}</CenteredBlock>
                    <StyledCarouselWrapper>
                        <VehicleProductCardCarousel header={''} text={''}>
                            {getBundleCards()}
                        </VehicleProductCardCarousel>
                    </StyledCarouselWrapper>
                </>
            ) : (
                <CenteredBlock>
                    {getTexts()}
                    <BundleCardsWrapper>
                        {allProductCards.map((product, index) => {
                            if (product.type === 'detailed') {
                                return (
                                    <VehicleProductListCard
                                        productCard={product}
                                        onCardClick={() => {
                                            uiHandler.removeScroll();
                                            selectBundle(product.id);
                                            setShowBundleDetailsDialog(true);
                                        }}
                                        key={`bundle-${product.id}-${index}`}
                                        isAboveFold={content.isAboveFold}
                                    />
                                );
                            }
                            if (product.type === 'marketing') {
                                return (
                                    <VehicleProductListMarketingCard
                                        key={`marketing-${product.index}`}
                                        backgroundImage={product.backgroundImage}
                                        header={product.header}
                                        description={product.description}
                                        cta={product.cTA ? product.cTA[0] : undefined}
                                        backgroundColor={product.backgroundColor}
                                        textColor={product.textColor}
                                        isAboveFold={content.isAboveFold}
                                    />
                                );
                            }
                            return null;
                        })}
                    </BundleCardsWrapper>
                </CenteredBlock>
            )}

            <BundleDetailsModal
                isVisible={showBundleDetailsDialog}
                oneMonthLeasingCost={selectedBundle?.discountPrice ?? 0}
                setupCost={selectedBundle?.establishmentFee ?? 0}
                header={bundleDialogHeader}
                bundleDialogStartupFrom={bundleDialogStartupFrom}
                bundleDialogOverviewOfferHeader={bundleDialogOverviewOfferHeader}
                bundleDialogPaymentPlanHeader={bundleDialogPaymentPlanHeader}
                bundleDialogSubmitButton={bundleDialogSubmitButton}
                overviewOfferPrices={[
                    {
                        key: 'Måndelig ydelse',
                        value: selectedBundle?.discountPrice
                            ? `${getFormattedValue(selectedBundle?.discountPrice)} ${Abbreviations.KR_SLASH_MD}`
                            : '-',
                    },
                    {
                        key: 'Udbetaling',
                        value: '0 kr.',
                    },
                    {
                        key: 'Løbetid',
                        value: `${getFormattedValue(fullDuration)} mdr.`,
                    },
                    {
                        key: 'Ejerafgift',
                        value: 'Inklusiv',
                    },
                    {
                        key: 'Service',
                        value: 'Inklusiv',
                    },
                    {
                        key: 'Forsikring',
                        value: 'Inklusiv',
                    },
                    {
                        key: 'Etableringsgebyr',
                        value: selectedBundle?.establishmentFee ? `${getFormattedValue(selectedBundle?.establishmentFee)} kr.` : '-',
                    },
                ]}
                hideSidebar={() => {
                    setShowBundleDetailsDialog(false);
                    uiHandler.applyScroll();
                }}
                contactForm={content.contactForm[0]}
                pageId={pageId}
                selectedBundle={selectedBundle ?? undefined}
                receiptHeader={bundleDialogReceiptHeader}
                receiptDescription={bundleDialogReceiptDescription}
                submitButton={bundleDialogReceiptSubmitButton}
                carsInBundles={content.carsInBundle ?? []}
                bundleName={selectedBundle ? `${selectedBundle.variant1Value} + ${selectedBundle.variant2Value}` : ''}
                isAboveFold={content.isAboveFold}
            />
        </StyledBundleSpot>
    );
};
