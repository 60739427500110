import { FC } from 'react';
import sanitizeHtml from 'sanitize-html';
import { CarFinancing } from '../../../../lib/api/models/hessel-api/vehicle';
import { ProductDetailsPage } from '../../../../lib/api/models/umbraco';
import { PdpCarFinanceSetup } from '../../../../lib/api/models/umbraco/product-details';
import { CarPurchaseOwnershipCashTab, CarPurchaseOwnershipFinanceTab } from '../../../../lib/api/models/umbraco/product-types/cars.types';
import { hesselViewModels } from '../../../../lib/view-models';
import { TabbedCard } from '../../../shared/tabbed-card/tabbed-card.component';
import { PriceByOwnership } from '../../hooks/price-by-ownership.types';
import { OwnershipCard } from '../../ownership-card/ownership-card.component';
import { useCarCtaConfig } from '../use-car-cta-config';
import { IncludingDeliveryAndComplianceTextWrapper, PositionedComplianceText, StyledIncludingDeliveryText } from './car-purchase-tabs.styled';
import { formatPrice, isNullOrEmpty } from '../../../../utils/helpers';
import { FinancialCalculations } from '../../financial-calculations/financial-calculations.component';

export type CarPurchaseTabsProps = {
    carFinancialSetup: Array<PdpCarFinanceSetup>;
    className?: string;
    page: ProductDetailsPage;
    priceVisibilityChanged: (visible: boolean) => void;
    product: hesselViewModels.ProductDetails;
    selectedOwnershipTab: hesselViewModels.OwnershipTab;
    setSelectedOwnershipTab: (tab: hesselViewModels.OwnershipTab) => void;
    priceInformation: PriceByOwnership;
    santanderFinancing?: CarFinancing;
};

export const CarPurchaseTabs: FC<CarPurchaseTabsProps> = ({
    carFinancialSetup,
    className,
    page,
    priceVisibilityChanged,
    product,
    selectedOwnershipTab,
    setSelectedOwnershipTab,
    priceInformation,
    santanderFinancing,
}) => {
    const CtaConfig = useCarCtaConfig(page, selectedOwnershipTab, product);

    const purchaseTabs = [];

    if (product.purchaseTypes.cash.show) {
        const carCashTabCms = carFinancialSetup.find((x) => x.alias === 'carPurchaseOwnershipCashTab') as CarPurchaseOwnershipCashTab;
        if (carCashTabCms) {
            const carCashTab = {
                id: 'Car Kontant',
                label: carCashTabCms?.tabName ?? '',
                description: carCashTabCms.subHeader ?? '',
                onClick: () => setSelectedOwnershipTab('Car Kontant'),
                content: (
                    <OwnershipCard
                        priceVisibilityChanged={priceVisibilityChanged}
                        primaryCta={{
                            label: CtaConfig[product.availability]?.labels.primary,
                            action: CtaConfig[product.availability]?.actions.primary,
                        }}
                        secondaryCta={{
                            label: CtaConfig[product.availability]?.labels.secondary,
                            action: CtaConfig[product.availability]?.actions.secondary,
                        }}
                        footerCta={{
                            label: CtaConfig[product.availability]?.labels.footerLink,
                            action: CtaConfig[product.availability]?.actions.footerLink,
                        }}
                        usps={carCashTabCms.usps}
                        price={priceInformation}
                        disclaimer={
                            <IncludingDeliveryAndComplianceTextWrapper>
                                {page?.carIncludingDeliveryText?.length > 0 ? (
                                    <StyledIncludingDeliveryText style={{ color: '#807f80' }}>
                                        {page.carIncludingDeliveryText}
                                    </StyledIncludingDeliveryText>
                                ) : null}
                            </IncludingDeliveryAndComplianceTextWrapper>
                        }
                    />
                ),
            };
            purchaseTabs.push(carCashTab);
        }
    }

    // Finance tab
    if (product.purchaseTypes.financing.show) {
        const financeTabCms = carFinancialSetup.find((x) => x.alias === 'carPurchaseOwnershipFinanceTab') as CarPurchaseOwnershipFinanceTab;
        if (financeTabCms) {
            const financeTab = {
                id: 'Car Finansiering',
                label: financeTabCms?.tabName ?? '',
                description: product.purchaseTypes.financing.durationMonths + ' mdr.',
                onClick: () => setSelectedOwnershipTab('Car Finansiering'),
                content: (
                    <OwnershipCard
                        priceVisibilityChanged={priceVisibilityChanged}
                        primaryCta={{
                            label: CtaConfig[product.availability]?.labels.primary,
                            action: CtaConfig[product.availability]?.actions.primary,
                        }}
                        secondaryCta={{
                            label: CtaConfig[product.availability]?.labels.secondary,
                            action: CtaConfig[product.availability]?.actions.secondary,
                        }}
                        footerCta={{
                            label: CtaConfig[product.availability]?.labels.footerLink,
                            action: CtaConfig[product.availability]?.actions.footerLink,
                        }}
                        usps={[
                            ...(financeTabCms?.usps ?? []),
                            {
                                label: `${financeTabCms?.durationLabel}: ${product.purchaseTypes.financing.durationMonths} mdr`,
                                icon: financeTabCms?.durationIcon,
                                isEmphasized: true,
                            },
                            {
                                label: `${financeTabCms?.fixedDebtorLabel ?? ''} ${
                                    santanderFinancing?.interestRate ? formatPrice(santanderFinancing?.interestRate, 2) + ' %' : ''
                                }`,
                                icon: financeTabCms?.fixedDebtorIcon,
                            },
                            {
                                label: `${financeTabCms?.aopLabel ?? ''} ${
                                    santanderFinancing?.aopBeforeTax ?? product.purchaseTypes.financing.aopBeforeTax
                                } %`,
                                icon: financeTabCms?.aopIcon,
                            },
                        ]}
                        price={priceInformation}
                        disclaimer={
                            <IncludingDeliveryAndComplianceTextWrapper>
                                {page?.carIncludingDeliveryText?.length > 0 ? (
                                    <StyledIncludingDeliveryText style={{ color: '#807f80' }}>
                                        {page.carIncludingDeliveryText}
                                    </StyledIncludingDeliveryText>
                                ) : null}
                                <PositionedComplianceText
                                    text={`Forbrug ved blandet kørsel: ${product.specifications.consumption}, CO2 udledning: ${
                                        product.specifications.co2Emission
                                    }, ${
                                        product.fuelType !== 'El' && !isNullOrEmpty(product.energyRating)
                                            ? 'energimærke: ' + product.energyRating
                                            : ''
                                    }. ${sanitizeHtml(
                                        `${santanderFinancing?.defaultDutyText ?? product.purchaseTypes.financing.santanderComplianceText}`,
                                        {
                                            allowedTags: [],
                                        }
                                    )}`}
                                />
                            </IncludingDeliveryAndComplianceTextWrapper>
                        }
                        priceSection={<FinancialCalculations page={page} product={product} />}
                    />
                ),
            };
            purchaseTabs.push(financeTab);
        }
    }

    return (
        <TabbedCard
            controlled
            activeTabId={selectedOwnershipTab}
            onAccesibilityTabSelected={(id) => setSelectedOwnershipTab(id as hesselViewModels.OwnershipTab)}
            className={className}
            tabs={purchaseTabs}
        />
    );
};
