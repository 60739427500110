import { parseISO } from 'date-fns';
import { useEffect, useMemo, useState, VFC } from 'react';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { useAccordionController } from '../../../../hooks/use-accordion-controller';
import { Bundle } from '../../../../lib/api/models/hessel-api';
import { CarDetails } from '../../../../lib/api/models/hessel-api/hire';
import { FormContentRenderModel, sharedTypes } from '../../../../lib/api/models/umbraco';
import { mapProductDetailsList } from '../../../../lib/mappers/vehicle/product-details.mapper';
import { HireContentDisplayStore } from '../../../../lib/state/hessel-site/display-manager';
import { hesselViewModels } from '../../../../lib/view-models';
import { DateStyle, formatDate, formatPrice, getFormattedValue, isNullOrEmpty, sanitizeMarkup } from '../../../../utils/helpers';
import { CtaHandler } from '../../../plus-sites-shared/content-handlers';
import { EquipmentDialog } from '../../../product-details/equipment-dialog';
import { ContentSpotRichText } from '../../../shared/content-spot-rich-text/content-spot-rich-text.component';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { SimpleAccordion } from '../../../shared/simple-accordion';
import { KeyValueTableRow } from '../../../shared/tables/key-value-table/row';
import { UmbracoForms } from '../../../spots/umbraco-forms';
import { useUI } from '../../../ui';
import { PaymentPlanDetails } from '../../_shared/payment-plan-details';
import {
    HeaderSection,
    Header,
    Price,
    Description,
    StyledTableWrapper,
    StyledPaymentPlanTable,
    Tables,
    StyledAccordionHeader,
    StyledAccordionDescription,
    CardsList,
    StyledBundleCard,
    StyledButton,
    EnergyConsumptionWrapper,
    StyledEnergyText,
    StyledEnergyRating,
    StyledCompletedForm,
    StyledHeader,
    StyledDescription,
    ClickableText,
} from './bundle-details-modal.styled';
import { Abbreviations, Units } from '../../../../constants/units-and-abbreviations';

type OverviewOffer = {
    key: string;
    value: string;
};

export type BundleDetailsModalProps = {
    header: string;
    bundleDialogStartupFrom: string;
    bundleDialogOverviewOfferHeader: string;
    bundleDialogPaymentPlanHeader: string;
    bundleDialogSubmitButton: string;
    isVisible: boolean;
    oneMonthLeasingCost: number;
    setupCost: number;
    overviewOfferPrices: Array<OverviewOffer>;
    hideSidebar: () => void;
    contactForm?: FormContentRenderModel;
    pageId: string;
    selectedBundle?: Bundle;
    receiptHeader: string;
    receiptDescription: string;
    submitButton?: sharedTypes.MixedCta;
    carsInBundles: CarDetails[];
    bundleName: string;
    isAboveFold?: boolean;
};

function getBundleImage(car: hesselViewModels.ProductDetails): string | undefined {
    const resource = car.resources.find((x) => x.type === 'image');

    if (resource?.type === 'image') {
        return resource.url;
    }
    return undefined;
}

export const BundleDetailsModal: VFC<BundleDetailsModalProps> = ({
    header,
    bundleDialogStartupFrom,
    bundleDialogOverviewOfferHeader,
    bundleDialogPaymentPlanHeader,
    bundleDialogSubmitButton,
    isVisible,
    hideSidebar,
    oneMonthLeasingCost,
    setupCost,
    overviewOfferPrices,
    contactForm,
    pageId,
    selectedBundle,
    receiptHeader,
    receiptDescription,
    submitButton,
    carsInBundles,
    bundleName,
    isAboveFold,
}) => {
    const { registerAccordion } = useAccordionController();

    const [showEquipmentDialog, setShowEquipmentDialog] = useState(false);

    const [showContactForm, setShowContactForm] = useState(false);

    const [contactFormCompleted, setContactFormCompleted] = useState(false);

    const { setShowDealershipListDialog } = HireContentDisplayStore.useStoreActions((actions) => actions);

    // GTM
    const { trackHireLeadCheckout } = useGtmTracking();
    const tracker = trackHireLeadCheckout();

    const clickableDescription = (
        <StyledDescription>
            Har du et spørgsmål kan du kontakt os via nedenstående formular eller{' '}
            <ClickableText
                onClick={() => {
                    setShowDealershipListDialog(true);
                    uiHandler.removeScroll();
                    tracker.sidebarOpened('Find afdeling');
                }}
            >
                find din nærmeste HesselHiRE afdeling her.
            </ClickableText>
        </StyledDescription>
    );

    const [selectedProduct, setSelectedProduct] = useState<hesselViewModels.ProductDetails | undefined>();
    const [bundleCars, setBundleCars] = useState<Array<hesselViewModels.ProductDetails> | undefined>();
    const [carData, setCarData] = useState<CarDetails[] | undefined>();
    const [selectedCar, setSelectedCar] = useState<number>();

    useEffect(() => {
        if (!selectedBundle) {
            return;
        }
        const carList = [];
        const car1 = carsInBundles.find((x) => x.vehicleId === selectedBundle.variant1Id);
        const car2 = carsInBundles.find((x) => x.vehicleId === selectedBundle.variant2Id);
        if (car1) {
            carList.push(car1);
        }
        if (car2) {
            carList.push(car2);
        }
        setCarData(carList);
        setBundleCars(mapProductDetailsList(carList));
    }, [carsInBundles, selectedBundle]);

    const totalPrice = useMemo(() => {
        if (!selectedBundle) return 0;
        const monthlyPayments = 12 * selectedBundle.discountPrice;
        return monthlyPayments + selectedBundle.establishmentFee;
    }, [selectedBundle]);

    const equipmentFiltered = useMemo(() => {
        if (selectedProduct && selectedBundle && carData && selectedCar) {
            const car1 = carData[0];
            const car2 = carData[1];
            const isCar1 = selectedCar === 1;
            const extraEquipment = isCar1
                ? car1.equipment.filter((x) => selectedBundle.equipments1.find((y) => y === x.equipmentIdCode))
                : car2.equipment.filter((x) => selectedBundle.equipments2.find((y) => y === x.equipmentIdCode));
            const extraEquipmentPackages = isCar1
                ? car1.equipmentPackages.filter((x) => selectedBundle.equipmentPackages1.find((y) => y === x.packageIdKey))
                : car2.equipmentPackages.filter((x) => selectedBundle.equipmentPackages2.find((y) => y === x.packageIdKey));
            const equipment = selectedProduct.standardEquipment['Car HiRE'].filter(
                (x) => !extraEquipment.find((y) => y.equipmentIdCode === x.equipmentIdCode)
            );
            const equipmentPackages = selectedProduct.standardEquipmentPackages['Car HiRE'].filter(
                (x) => !extraEquipmentPackages.find((y) => y.packageIdKey === x.packageIdKey)
            );
            return {
                extraEquipment,
                extraEquipmentPackages,
                equipment,
                equipmentPackages,
            };
        }
    }, [carData, selectedBundle, selectedCar, selectedProduct]);

    const uiHandler = useUI();

    return (
        <>
            <SidePanel isVisible={isVisible} cancelAction={() => hideSidebar()}>
                <SidePanelLayout
                    closeSidePanel={() => {
                        hideSidebar();
                    }}
                >
                    <HeaderSection>
                        <Header>{header}</Header>
                        <Price>{`${getFormattedValue(oneMonthLeasingCost)} ${Abbreviations.KR_SLASH_MD}`}</Price>
                    </HeaderSection>

                    {selectedBundle?.fromDate ? (
                        <Description
                            {...sanitizeMarkup(
                                bundleDialogStartupFrom?.replace(
                                    '{{startup_from}}',
                                    `<strong>${formatDate(parseISO(selectedBundle.fromDate), DateStyle.dk_full_text_with_year)}</strong>`
                                )
                            )}
                        />
                    ) : null}

                    <CardsList>
                        {bundleCars?.map((car, index) => {
                            return (
                                <StyledBundleCard
                                    key={`bundle-car-variantId:${car.variantId}-id:${car.id}-${selectedBundle?.id}-${index}`}
                                    badgeText={`Bil nr. ${index + 1}`}
                                    kmPerMonth={selectedBundle ? `${getFormattedValue(selectedBundle.kmPrMo)} ${Abbreviations.KM_SLASH_MD}` : '0'}
                                    model={car.name}
                                    onLinkClick={() => {
                                        setSelectedProduct(car);
                                        setSelectedCar(index + 1);
                                        setShowEquipmentDialog(true);
                                    }}
                                    image={getBundleImage(car)}
                                    vehicleUrl={car.url}
                                    isAboveFold={isAboveFold}
                                    discontinued={car.discontinued}
                                />
                            );
                        })}

                        <SimpleAccordion
                            key={`overview-offer`}
                            title={''}
                            border={'none'}
                            headerSize="0px"
                            textColor={'0B0B0B'}
                            {...registerAccordion(0)}
                            contentPadding={'15px 0px'}
                            toggleIndicator="plus"
                            customTitle={<StyledAccordionHeader>{bundleDialogOverviewOfferHeader}</StyledAccordionHeader>}
                        >
                            <StyledAccordionDescription>
                                Herunder får du et overblik over, hvad der er inkluderet i aftalen.
                            </StyledAccordionDescription>
                            <Tables>
                                <StyledTableWrapper>
                                    <StyledPaymentPlanTable>
                                        {overviewOfferPrices?.map((row, i) => {
                                            const { key, value } = row;
                                            return <KeyValueTableRow {...{ label: key, value }} key={i} />;
                                        })}
                                    </StyledPaymentPlanTable>
                                </StyledTableWrapper>
                            </Tables>
                        </SimpleAccordion>

                        <SimpleAccordion
                            key={`payment-plan`}
                            title={''}
                            border={'none'}
                            headerSize="0px"
                            textColor={'0B0B0B'}
                            {...registerAccordion(1)}
                            contentPadding={'15px 0px'}
                            toggleIndicator="plus"
                            customTitle={<StyledAccordionHeader>{bundleDialogPaymentPlanHeader}</StyledAccordionHeader>}
                        >
                            <StyledAccordionDescription>
                                {`Herunder kan du se førstegangsydelsen og hele betalingsplanen for denne 6+6 kombination.`}
                            </StyledAccordionDescription>

                            <PaymentPlanDetails
                                numberOfMonths={12}
                                oneMonthLeasingCost={oneMonthLeasingCost}
                                handlingFee={setupCost}
                                indexOfNoCostMonths={[3, 4, 5]}
                            />
                        </SimpleAccordion>
                    </CardsList>

                    <StyledButton onClick={() => setShowContactForm(true)} variant="primary">
                        <p>{bundleDialogSubmitButton}</p>
                    </StyledButton>
                    <EnergyConsumptionWrapper>
                        {bundleCars && bundleCars?.length > 1 && carData && carData.length > 1 ? (
                            <StyledEnergyText>
                                <span>
                                    {carData[0].brand} {selectedBundle?.variant1Value}:
                                    {bundleCars[0].energyRating !== null ? (
                                        <span style={{ marginRight: '10px', marginLeft: '5px', opacity: 1 }}>
                                            <StyledEnergyRating rating={bundleCars[0].energyRating} />
                                        </span>
                                    ) : (
                                        <>&nbsp;</>
                                    )}
                                    Forbrug v. blandet kørsel{' '}
                                    {`${carData[0].specification.mixedDriving} ${carData[0].specification.mixedDrivingValue}.`}, Co2 emission{' '}
                                    {carData[0].specification.co2Emission ?? 0} g/{Units.km}
                                </span>{' '}
                                <span>
                                    {carData[1].brand} {selectedBundle?.variant2Value}:
                                    {bundleCars[1].energyRating !== null ? (
                                        <span style={{ marginRight: '10px', marginLeft: '5px', opacity: 1 }}>
                                            <StyledEnergyRating rating={bundleCars[1].energyRating} />
                                        </span>
                                    ) : (
                                        <>&nbsp;</>
                                    )}
                                    Forbrug v. blandet kørsel{' '}
                                    {`${carData[1].specification.mixedDriving} ${carData[1].specification.mixedDrivingValue}.`}, Co2 emission{' '}
                                    {carData[1].specification.co2Emission ?? 0} g/{Units.km}
                                </span>{' '}
                                <span>
                                    Månedlig ydelse {formatPrice(selectedBundle?.discountPrice ?? 0)} kr., 12 måneders løbetid med gns.{' '}
                                    {formatPrice(selectedBundle?.kmPrMo ?? 0)} {Units.km} til rådighed pr. måned. 0 kr. i udbetaling. Etableringsgebyr{' '}
                                    {formatPrice(selectedBundle?.establishmentFee ?? 0)} kr. Samlet omkostning ved 12 måneders leasing{' '}
                                    {formatPrice(totalPrice)} kr. Alle priser inkl. moms. Aftale forudsætter godkendt kreditvurdering. Forbehold for
                                    trykfejl, pris-, rente- og afgiftsændringer. Bilerne kan være vist med ekstraudstyr. Tilbuddet gælder et begrænset
                                    antal biler.
                                </span>
                            </StyledEnergyText>
                        ) : null}
                    </EnergyConsumptionWrapper>
                </SidePanelLayout>
            </SidePanel>

            {contactForm ? (
                <SidePanel isVisible={showContactForm} cancelAction={() => setShowContactForm(false)}>
                    <SidePanelLayout
                        closeSidePanel={() => {
                            uiHandler.applyScroll();
                            setShowContactForm(false);
                        }}
                    >
                        {!contactFormCompleted ? (
                            <UmbracoForms
                                form={contactForm.form}
                                pageId={pageId}
                                header={contactForm.formHeader}
                                subtext={contactForm.formSubtext}
                                onFormComplete={() => setContactFormCompleted(true)}
                                specialProps={{ bundleId: bundleName }}
                            />
                        ) : (
                            <StyledCompletedForm>
                                <StyledHeader>{!isNullOrEmpty(receiptHeader) ? receiptHeader : 'Tak for din henvendelse'}</StyledHeader>

                                {isNullOrEmpty(receiptDescription) ? clickableDescription : <ContentSpotRichText text={receiptDescription} />}

                                {submitButton ? (
                                    <CtaHandler
                                        ctas={[submitButton]}
                                        extraClickActions={() => {
                                            hideSidebar();
                                            setShowEquipmentDialog(false);
                                            setShowContactForm(false);
                                            setSelectedProduct(undefined);
                                            setShowDealershipListDialog(false);
                                            setContactFormCompleted(false);
                                        }}
                                    />
                                ) : null}
                            </StyledCompletedForm>
                        )}
                    </SidePanelLayout>
                </SidePanel>
            ) : null}

            <EquipmentDialog
                visible={showEquipmentDialog}
                hideModal={() => {
                    setShowEquipmentDialog(false);
                    setSelectedProduct(undefined);
                    uiHandler.removeScroll();
                }}
                standardEquipmentList={equipmentFiltered?.equipment}
                standardEquipmentPackage={equipmentFiltered?.equipmentPackages}
                extraEquipmentList={equipmentFiltered?.extraEquipment}
                extraEquipmentPackage={equipmentFiltered?.extraEquipmentPackages}
                includedEquipmentText={'Inkluderet'}
                storeSelectedTabOverride="Car HiRE"
            />
        </>
    );
};
