import { FC } from 'react';
import { uiTypes } from '../../../lib';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { CtaHandler } from '../../plus-sites-shared/content-handlers';
import { Separator } from '../separator';
import { SvgIcon } from '../svg-icon';
import {
    HeroCardWrapper,
    Tag,
    Header,
    Price,
    ValueForCustomer,
    BenefitList,
    BenefitItem,
    BenefitText,
    StylePreserver,
    StyledSpotHeader,
} from './hero-card.styled';
import { isNullOrEmpty } from '../../../utils/helpers';
import { getSpotOpacity } from '../../spots/helpers';
import { contentSpotTypes } from '../../../lib/api';
import { hexToRGBA } from '../../../utils/helpers/color.helpers';

type Props = {
    tag: string;
    headline: string;
    headerType: uiTypes.HeaderType;
    headerSize?: uiTypes.HeaderSize;
    price: string;
    priceSubText: string;
    agreements: string[];
    cta?: sharedTypes.MixedCta;
    backgroundColor: string;
    backgroundOpacity: contentSpotTypes.SpotOpacity;
    textColor: string;
    horizontalLineColor: string;
};

export const HeroCard: FC<Props> = ({
    tag,
    headline,
    headerType,
    headerSize,
    price,
    priceSubText,
    agreements,
    cta,
    backgroundColor,
    backgroundOpacity,
    textColor,
    horizontalLineColor,
}) => {
    const styledTextColor = {
        color: textColor && textColor.length > 0 ? `#${textColor}` : '',
    };

    return (
        <HeroCardWrapper
            bgColor={backgroundColor && backgroundColor.length > 0 ? hexToRGBA(backgroundColor, getSpotOpacity(backgroundOpacity)) : ''}
            textColor={styledTextColor.color}
        >
            <Tag textColor={`#${textColor}`}>{tag}</Tag>

            {isNullOrEmpty(headerSize) ? (
                <StylePreserver>
                    <Header as={headerType} style={styledTextColor}>
                        {headline}
                    </Header>
                </StylePreserver>
            ) : (
                <StyledSpotHeader headerType={headerType} headerSize={headerSize} style={styledTextColor}>
                    {headline}
                </StyledSpotHeader>
            )}

            <Price>{price}</Price>
            <ValueForCustomer>{priceSubText}</ValueForCustomer>
            <Separator
                marginBottom="1.5rem"
                marginTop="1rem"
                stylings={{
                    borderBottomColor: horizontalLineColor && horizontalLineColor.length > 0 ? `#${horizontalLineColor}` : '',
                }}
            />
            <BenefitList>
                {agreements.map((x, index) => {
                    return (
                        <BenefitItem key={`benefit-item-${index}`}>
                            <SvgIcon iconName="checkmark" customColor={styledTextColor.color} />
                            <BenefitText>{x}</BenefitText>
                        </BenefitItem>
                    );
                })}
            </BenefitList>
            {cta ? <CtaHandler ctas={[cta]} /> : null}
        </HeroCardWrapper>
    );
};
