import { FC, useEffect, useMemo, useState } from 'react';
import { getBundlesByVariant } from '../../../lib/api/hire/hessel-hire-bundles-api';
import { getCarByIds } from '../../../lib/api/vehicle/hessel-vehicle-pdp-api';
import { Bundle } from '../../../lib/api/models/hessel-api';
import { CarDetails } from '../../../lib/api/models/hessel-api/hire';
import { GlobalProductListSettings, UmbracoForm } from '../../../lib/api/models/umbraco';
import { mapBundleDetailsToProductCard } from '../../../lib/mappers/vehicle/product-card.mapper';
import { ProductCard } from '../../../lib/view-models/vehicle';
import { getFormattedValue } from '../../../utils/helpers';
import { VehicleProductCardCarousel } from '../../vehicle/vehicle-product-card-carousel/vehicle-product-card-carousel.component';
import { BundleDetailsModal } from '../../hire/six-plus-six/bundle-details-modal';
import { useUI } from '../../ui';
import { StyledBundleWrapper } from '../product-details-page/product-details-page.styled';
import { Abbreviations } from '../../../constants/units-and-abbreviations';
import { VehicleProductListCard } from '../../vehicle/vehicle-product-list/vehicle-product-list-card';

type IProps = {
    globalEcomSettings: GlobalProductListSettings;
    pageId: string;
    header: string;
    bodyText: string;
    contactForm: UmbracoForm;
    vehicleId: string;
};

export const PartOfBundle: FC<IProps> = ({ globalEcomSettings, pageId, header, bodyText, contactForm, vehicleId }) => {
    const [showBundleDetailsDialog, setShowBundleDetailsDialog] = useState(false);
    const [bundles, setBundles] = useState<Bundle[]>([]);
    const [bundleCards, setBundleCards] = useState<ProductCard[]>([]);
    const [selectedBundle, setSelectedBundle] = useState<Bundle | null>(null);
    const [carsInBundles, setCarsInBundles] = useState<CarDetails[] | null>(null);

    const uiHandler = useUI();

    useEffect(() => {
        const getBundles = async () => {
            const [result, error] = await getBundlesByVariant(vehicleId);
            if (result && result.bundles && !error) {
                setBundles(result.bundles);
                if (result.bundles.length > 0) {
                    const vehicleIdsInBundles: string[] = result.bundles.reduce(
                        (acc, curr) => [...acc, curr.variant1Id, curr.variant2Id],
                        new Array<string>()
                    );
                    const [vehicles, vehiclesError] = await getCarByIds(vehicleIdsInBundles);
                    if (vehicles && !vehiclesError) {
                        setCarsInBundles(vehicles);
                    }
                }
            } else {
                setCarsInBundles([]);
                setBundleCards([]);
            }
        };
        getBundles();
    }, [vehicleId]);

    useEffect(() => {
        if (carsInBundles && carsInBundles.length > 1) {
            setBundleCards(
                bundles?.map((x) => mapBundleDetailsToProductCard(x, globalEcomSettings.hireBundleUSPs, carsInBundles[0], carsInBundles[1]))
            );
        }
    }, [bundles, carsInBundles, globalEcomSettings.hireBundleUSPs]);

    const selectBundle = (bundleId: string) => {
        const bundleToSelect = bundles.find((x) => x.id === bundleId);
        if (bundleToSelect) {
            setSelectedBundle(bundleToSelect);
        }
    };

    const fullDuration = useMemo(() => {
        if (selectedBundle) {
            return selectedBundle.durationVehicle1 + selectedBundle.durationVehicle2;
        }
        return 0;
    }, [selectedBundle]);

    if (!bundleCards || bundleCards?.length === 0) return null;

    return (
        <StyledBundleWrapper>
            <VehicleProductCardCarousel header={header} text={bodyText}>
                {bundleCards.map((product, index) => {
                    return (
                        <div key={`${product.id}-${index}`} style={{ maxWidth: '320px' }}>
                            <VehicleProductListCard
                                productCard={product}
                                className="keen-slider__slide"
                                onCardClick={() => {
                                    uiHandler.removeScroll();
                                    selectBundle(product.id);
                                    setShowBundleDetailsDialog(true);
                                }}
                                allowCarousel={false}
                            />
                        </div>
                    );
                })}
            </VehicleProductCardCarousel>
            <BundleDetailsModal
                isVisible={showBundleDetailsDialog}
                oneMonthLeasingCost={selectedBundle?.discountPrice ?? 0}
                setupCost={selectedBundle?.establishmentFee ?? 0}
                header={'6+6 Hessel HiRE tilbud'}
                overviewOfferPrices={[
                    {
                        key: 'Måndelig ydelse',
                        value: selectedBundle?.discountPrice
                            ? `${getFormattedValue(selectedBundle?.discountPrice)} ${Abbreviations.KR_SLASH_MD}`
                            : '-',
                    },
                    {
                        key: 'Udbetaling',
                        value: selectedBundle?.establishmentFee ? `${getFormattedValue(selectedBundle.establishmentFee)} ${Abbreviations.KR}` : '-',
                    },
                    {
                        key: 'Løbetid',
                        value: `${getFormattedValue(fullDuration)} ${Abbreviations.MDR}`,
                    },
                    {
                        key: 'Ejerafgift',
                        value: 'Inklusiv',
                    },
                    {
                        key: 'Service',
                        value: 'Inklusiv',
                    },
                    {
                        key: 'Forsikring',
                        value: 'Inklusiv',
                    },
                    {
                        key: 'Etableringsgebyr',
                        value: selectedBundle?.establishmentFee ? `${getFormattedValue(selectedBundle.establishmentFee)} kr.` : '-',
                    },
                ]}
                hideSidebar={() => {
                    setShowBundleDetailsDialog(false);
                    uiHandler.applyScroll();
                }}
                pageId={pageId}
                contactForm={{
                    form: contactForm,
                    formHeader: 'Kontakt os',
                    formSubtext: '',
                }}
                selectedBundle={selectedBundle ?? undefined}
                bundleDialogStartupFrom={globalEcomSettings.bundleDialogStartupFrom}
                bundleDialogOverviewOfferHeader={globalEcomSettings.bundleDialogOverviewOfferHeader}
                bundleDialogPaymentPlanHeader={globalEcomSettings.bundleDialogPaymentPlanHeader}
                bundleDialogSubmitButton={globalEcomSettings.bundleDialogSubmitButton}
                receiptHeader={globalEcomSettings.bundleDialogReceiptHeader ?? ''}
                receiptDescription={globalEcomSettings.bundleDialogReceiptDescription ?? ''}
                submitButton={
                    globalEcomSettings.bundleDialogReceiptSubmitButton && globalEcomSettings.bundleDialogReceiptSubmitButton.length > 0
                        ? globalEcomSettings.bundleDialogReceiptSubmitButton[0]
                        : undefined
                }
                carsInBundles={carsInBundles ?? []}
                bundleName={selectedBundle ? `${selectedBundle.variant1Value} + ${selectedBundle.variant2Value}` : ''}
            />
        </StyledBundleWrapper>
    );
};
