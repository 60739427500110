import { VFC } from 'react';
import {
    BgImage,
    CardWrapper,
    LinkText,
    LinkTextSpan,
    StyledMarketingCardDescription,
    StyledMarketingCardHeader,
    TextArea,
} from './vehicle-product-list-marketingcard.styled';
import { cmsUrlWithoutSiteId, getCmsImageUrl, isNullOrEmpty } from '../../../../utils/helpers';
import { sharedTypes } from '../../../../lib/api/models/umbraco';
import { CtaHandler } from '../../../plus-sites-shared/content-handlers';
import Link from 'next/link';
import { SvgIcon } from '../../../shared/svg-icon';

type VehicleProductListMarketingCardProps = {
    backgroundImage?: sharedTypes.Image;
    header: string;
    cta?: sharedTypes.MixedCta;
    backgroundColor: string;
    textColor: string;
    description: string;
    isAboveFold?: boolean;
    className?: string;
};

export const VehicleProductListMarketingCard: VFC<VehicleProductListMarketingCardProps> = ({
    backgroundImage,
    header,
    cta,
    backgroundColor,
    textColor,
    description,
    isAboveFold = false,
    className,
}) => {
    return (
        <CardWrapper style={{ backgroundColor: `#${backgroundColor}` }} className={className}>
            {backgroundImage ? (
                <BgImage src={getCmsImageUrl(backgroundImage.src)} alt="Product Card: Car Image" loading={isAboveFold ? 'eager' : 'lazy'} />
            ) : null}
            <TextArea>
                <StyledMarketingCardHeader style={{ color: `#${textColor}` }}>{header}</StyledMarketingCardHeader>
                {!isNullOrEmpty(description) ? (
                    <StyledMarketingCardDescription style={{ color: `#${textColor}` }}>{description}</StyledMarketingCardDescription>
                ) : null}
                {cta ? (
                    <div style={{ marginTop: '20px' }}>
                        {cta.alias === 'cTAWithLink' && cta.color === 'link' ? (
                            <Link href={cmsUrlWithoutSiteId(cta.linkUrl.url)} passHref>
                                <LinkText>
                                    <LinkTextSpan>{cta.text}</LinkTextSpan>
                                    <SvgIcon iconName={'arrow/right'} color="light" />
                                </LinkText>
                            </Link>
                        ) : (
                            <CtaHandler ctas={[cta]}></CtaHandler>
                        )}
                    </div>
                ) : null}
            </TextArea>
        </CardWrapper>
    );
};

export default VehicleProductListMarketingCard;
