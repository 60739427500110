import { deleteAPI, getAPI, postAPI, putAPI } from '../helpers';
import {
    CartCustomer,
    CartObject,
    CartOrderline,
    DealershipPickupRequest,
    HomeDeliveryAlternativeAddress,
    HomeDeliveryRequest,
    ParcelShopPickupRequest,
    ReepayCheckoutRequest,
    ReepayCheckoutResponse,
} from '../models/shop/cart';
import { DeliveryOptionType } from '../models/shop/shop-api.models';
import { APIResponse } from '../types';
import { EHApiGatewayControllersProductDetailsParameter } from '../../api-types';
import { ParcelShop } from '../models/shop/parcel-shops';
import { DealershipInformationPage } from '../models/umbraco';

export const rebuildStaleCart = async (productSku: string, cartOrderlines: CartOrderline[]): APIResponse<CartObject> => {
    //The purpose of this function is to ATTEMPT to rebuild a stale cart.
    //We are not cathing every possible error in this function, but we are trying to create a new cart that resembles the stale cart as closely as possible.
    try {
        const [newCart, error] = await addProductToCart(productSku, null); //Create a new cart
        if (newCart && !error) {
            const allPromises: APIResponse<CartObject>[] = [];
            cartOrderlines.forEach((sku) => {
                const addProductPromise = addProductToCart(sku.productSku, newCart.id, sku.quantity);
                allPromises.push(addProductPromise);
            });
            const addProducts = await Promise.all(allPromises); //Add all products from stale cart to new cart.
            const successfullRequests = addProducts //Filter out bad requests (some products from stale cart might now be unavailable).
                .map((x) => {
                    const [cart, error] = x;
                    if (cart && !error) return cart;
                    return undefined;
                })
                .filter((y) => y !== undefined);
            //As all promises are awaited simultaneously, we sort them based on which cart has the most line items (they might finish in a different order than they are started)
            if (successfullRequests.length > 0) {
                const successfullRequestsOrdered = successfullRequests.sort((a, b) =>
                    (a as CartObject).lineItems.length > (b as CartObject).lineItems.length ? -1 : 1
                );
                return [successfullRequestsOrdered[0], undefined];
            }
        }
        return [
            undefined,
            {
                errorType: 'UNKNOWN',
                statusCode: 400,
            },
        ];
    } catch (error) {
        return [
            undefined,
            {
                errorType: 'UNKNOWN',
                statusCode: 400,
            },
        ];
    }
};

export const addProductToCart = async (productSku: string, cartId: string | null, quantity = 1): APIResponse<CartObject> => {
    const postUrl = cartId ? `/api/v2/commerce/basket/${cartId}/sku/${productSku}` : `/api/v2/commerce/basket/sku/${productSku}`;
    return postAPI<CartObject>(postUrl, {
        quantity,
    });
};

export const updateOrderlineQuantity = async (productSku: string, quantity: number, cartId: string): APIResponse<CartObject> => {
    const body: EHApiGatewayControllersProductDetailsParameter = {
        quantity: quantity,
        installation: null,
    };
    return putAPI<CartObject>(`/api/v2/commerce/basket/${cartId}/sku/${productSku}`, body);
};

export const deleteOrderline = async (productSku: string, cartId: string): APIResponse<CartObject> => {
    return deleteAPI<CartObject>(`/api/v2/commerce/basket/${cartId}/sku/${productSku}`);
};

export const addCustomerToCart = async (customerInfo: CartCustomer, cartId: string): APIResponse<CartObject> => {
    return postAPI<CartObject>(`/api/v2/commerce/basket/${cartId}/customer`, customerInfo);
};

export const startCheckoutPayment = async (request: ReepayCheckoutRequest): APIResponse<ReepayCheckoutResponse> => {
    return postAPI<ReepayCheckoutResponse>(`/api/v1/payment/startcheckout`, request);
};

export const getDeliveryOptions = async (cartId: string): APIResponse<DeliveryOptionType[]> => {
    return getAPI<DeliveryOptionType[]>(`/api/v2/commerce/delivery-options/${cartId}`);
};

export const setDealershipAsDelivery = async (cartId: string, dealership: DealershipInformationPage): APIResponse<CartObject> => {
    const requestBody: DealershipPickupRequest = {
        pickUpAtHessel: {
            shippingMethod: 'PickUp_at_Hessel',
            pickUpAddress: {
                addressLine1: dealership.address,
                city: dealership.city,
                pickUpId: dealership.hovedafdelingId,
                zipCode: dealership.zipcode,
                companyName: dealership.displayName,
            },
        },
    };
    return postAPI<CartObject>(`/api/v2/commerce/delivery-options/${cartId}`, requestBody);
};

export const setParcelShopPickupAsDelivery = async (cartId: string, parcelShop: ParcelShop): APIResponse<CartObject> => {
    const requestBody: ParcelShopPickupRequest = {
        sendToPickUpPoint: {
            shippingMethod: 'PickUp_Point',
            pickUpAddress: {
                addressLine1: parcelShop.address,
                city: parcelShop.city,
                pickUpId: parcelShop.id,
                zipCode: parcelShop.zipcode,
                companyName: parcelShop.name,
            },
        },
    };
    return postAPI<CartObject>(`/api/v2/commerce/delivery-options/${cartId}`, requestBody);
};

export const setHomeDeliveryAsDelivery = async (
    cartId: string,
    alternativeDeliveryAddres: HomeDeliveryAlternativeAddress | null
): APIResponse<CartObject> => {
    const requestBody: HomeDeliveryRequest = {
        sendToAddress: {
            shippingMethod: 'Send_to_Address',
            deliveryAddress: alternativeDeliveryAddres,
            deliveryCustomerName: alternativeDeliveryAddres?.name,
        },
    };
    return postAPI<CartObject>(`/api/v2/commerce/delivery-options/${cartId}`, requestBody);
};
