import { VFC } from 'react';
import {
    BorderBox,
    InputSliderWrapper,
    MinMaxWrapper,
    StyledMinMaxText,
    StyledRange,
    StyledSlider,
    StyledThumb,
    StyledTrack,
} from './stepped-options-slider.styled';
import { SliderOption } from './stepped-options-slider.props';

type Props = {
    options: SliderOption<string>[];
    defaultValue?: SliderOption<string>;
    value?: SliderOption<string>;
    unit?: string;
    onValueChange: (value: SliderOption<string>) => void;
};

export const SteppedOptionsSlider: VFC<Props> = ({ defaultValue, value, unit, options, onValueChange }) => {
    return (
        <BorderBox>
            <InputSliderWrapper>
                <StyledSlider
                    defaultValue={[options.findIndex((options) => options.value === defaultValue?.value)]}
                    max={options.length - 1}
                    step={1}
                    aria-label="Price"
                    value={[options.findIndex((options) => options.value === value?.value)]}
                    onValueChange={(e) => {
                        onValueChange(options[e[0]]);
                    }}
                >
                    <StyledTrack>
                        <StyledRange />
                    </StyledTrack>
                    <StyledThumb />
                </StyledSlider>
            </InputSliderWrapper>
            <MinMaxWrapper>
                {options.map((x, index) => {
                    return (
                        <StyledMinMaxText key={`${x.value}-${index}`}>
                            {x.displayValue}
                            {unit ? `${unit}` : ''}
                        </StyledMinMaxText>
                    );
                })}
            </MinMaxWrapper>
        </BorderBox>
    );
};
