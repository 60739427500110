import { FC, useEffect, useMemo, useState } from 'react';
import { VehicleProductRibbonWrapper } from './vehicle-product-ribbon-layout.styled';
import { CarBaseFilterSetup, PriceType, ProductRibbon } from '../../../lib/api/models/umbraco/content-spot';
import { VehicleProductCardCarousel } from '../vehicle-product-card-carousel/vehicle-product-card-carousel.component';
import { generatePdpUrl, getDealershipsForTestDrives, getProductCardScrollToId, isNullOrEmpty } from '../../../utils/helpers';
import Link from 'next/link';
import { BookTestDriveModal } from '../../organization/book-test-drive-modal/book-test-drive-modal.component';
import { getPageByDataTypeAlias } from '../../../lib/api';
import { DealershipInformationPage } from '../../../lib/api/models/umbraco';
import { ProductCardDetails } from '../../../lib/view-models/vehicle';
import { useScrollLock } from '../../../hooks/use-scroll-lock';
import { VehicleProductListCard } from '../vehicle-product-list/vehicle-product-list-card';
import { DefaultBaseFilterSetup } from '../../../constants/site-consts';
import { filterMarketingCardOnAvailability } from '../../../utils/helpers/marketing-card.helpers';
import { VehicleProductListMarketingCard } from '../vehicle-product-list/vehicle-product-list-marketingcard';

export type VehicleProductRibbonProps = {
    content: ProductRibbon;
};

export const VehicleProductRibbon: FC<VehicleProductRibbonProps> = ({ content }) => {
    const [departmentOptions, setDepartmentOptions] = useState(new Array<DealershipInformationPage>());
    const [bookTestDriveFormOpen, setBookTestDriveFormOpen] = useState(false);
    const [selectedDemoVehicle, setSelectedDemoVehicle] = useState<ProductCardDetails | undefined>();

    const baseFilterSetup = useMemo(() => {
        if (content.baseFilterSetup && content.baseFilterSetup.length > 0) return content.baseFilterSetup[0];
        return DefaultBaseFilterSetup;
    }, [content]);

    const allCards = useMemo(() => {
        if (!content.filterConfiguration.marketingProductCards) {
            return content.initialProducts;
        }
        const availabilities = baseFilterSetup.availabilities.length > 0 ? baseFilterSetup.availabilities : [baseFilterSetup.defaultAvailability];
        if (!availabilities.length) {
            return content.initialProducts;
        }

        const productCards = content.initialProducts ?? [];
        const marketingProductCards = content.filterConfiguration.marketingProductCards ?? [];

        const allCards = [...productCards];
        const listLength = productCards ? productCards.length : 0;

        const filteredMarketingCards = marketingProductCards.filter(
            (x) =>
                x.index <= listLength &&
                (x.availabilityList?.length
                    ? availabilities?.length > 1
                        ? availabilities.some((availability) => filterMarketingCardOnAvailability(availability, x.availabilityList))
                        : filterMarketingCardOnAvailability(availabilities?.[0], x.availabilityList)
                    : true)
        );
        for (const card of filteredMarketingCards) {
            allCards.splice(card.index - 1, 0, { ...card, type: 'marketing' });
        }
        return allCards;
    }, [content.initialProducts, content.filterConfiguration, baseFilterSetup]);

    const { hideScrollBars, applyScrollBars } = useScrollLock();

    useEffect(() => {
        const getDepartments = async () => {
            const [departments, error] = await getPageByDataTypeAlias('dealershipInformation');
            if (departments && !error) {
                setDepartmentOptions(getDealershipsForTestDrives(departments as DealershipInformationPage[]));
            }
        };
        if (
            departmentOptions.length === 0 &&
            content.initialProducts &&
            content.initialProducts.length > 0 &&
            content.initialProducts[0].type === 'detailed' &&
            content.initialProducts[0].availability === 'Demo'
        ) {
            getDepartments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.initialProducts]);

    const seeMoreLink = useMemo(() => {
        const maxProducts = content.maxNumberOfProducts === 0 ? 12 : content.maxNumberOfProducts;
        if (content.initialProducts && content.initialProducts.length < maxProducts) {
            return undefined;
        }
        return content.seeAllLink;
    }, [content.initialProducts, content.maxNumberOfProducts, content.seeAllLink]);

    const priceType: PriceType = useMemo(() => {
        if (baseFilterSetup.alias === 'carBaseFilter' && baseFilterSetup.showAsCompanyCars) {
            return baseFilterSetup.defaultPaymentType === 'Leasing' ? 'BusinessLeasing' : 'Taxation';
        }
        return baseFilterSetup.defaultPaymentType === 'Buy' ? 'Purchase' : 'Leasing';
    }, [baseFilterSetup]);

    if (content.initialProducts === undefined || content.initialProducts.length === 0) return null;

    const getRibbonInitialIndex = () => {
        if (!process.browser) {
            return 0;
        }

        const storedProductIdAndUrl = getProductCardScrollToId();

        if (content.initialProducts === undefined || content.initialProducts.length === 0 || !storedProductIdAndUrl) {
            return 0;
        }

        const [storedProductId] = storedProductIdAndUrl.split('__');

        if (isNullOrEmpty(storedProductId)) {
            return 0;
        }

        const productCardIndex = content.initialProducts.findIndex((product) => product.id === storedProductId);

        return productCardIndex === -1 ? 0 : productCardIndex;
    };

    return (
        <>
            <VehicleProductRibbonWrapper id={content.scrollAnchorId}>
                <VehicleProductCardCarousel
                    header={content.header}
                    headerType={content.headerType}
                    headerSize={content.headerSize}
                    text={content.text}
                    link={seeMoreLink}
                    initialIndex={getRibbonInitialIndex()}
                >
                    {allCards?.map((product, index) => {
                        const location =
                            departmentOptions.length > 0 && product.type === 'detailed' && product.locationId
                                ? departmentOptions.find((x) => x.hovedafdelingId === product.locationId)?.dealershipLabel
                                : undefined;
                        return product.type === 'detailed' && product.availability === 'Demo' ? (
                            <div key={`${product.id}-${index}`}>
                                <VehicleProductListCard
                                    productCard={product}
                                    className="keen-slider__slide"
                                    allowCarousel={false}
                                    isAboveFold={content.isAboveFold}
                                    priceType={priceType}
                                    locationBadge={location}
                                    ctaSettings={{
                                        onClick: () => {
                                            hideScrollBars();
                                            setSelectedDemoVehicle(product);
                                            setBookTestDriveFormOpen(true);
                                        },
                                        text: 'Book prøvetur',
                                    }}
                                />
                            </div>
                        ) : product.type === 'marketing' ? (
                            <VehicleProductListMarketingCard
                                className="keen-slider__slide keen-slider__slide--marketingcard"
                                key={`marketing-${product.index}`}
                                backgroundImage={product.backgroundImage}
                                header={product.header}
                                description={product.description}
                                cta={product.cTA ? product.cTA[0] : undefined}
                                backgroundColor={product.backgroundColor}
                                textColor={product.textColor}
                                isAboveFold={content.isAboveFold}
                            />
                        ) : (
                            <Link
                                key={`${product.id}-${index}`}
                                href={
                                    generatePdpUrl(
                                        product,
                                        baseFilterSetup.alias === 'carBaseFilter' && baseFilterSetup.showAsCompanyCars,
                                        priceType === 'BusinessLeasing' || priceType === 'Leasing' ? 'Leasing' : 'Køb',
                                        (product as ProductCardDetails)?.category as 'Car' | 'Van'
                                    ) ?? undefined
                                }
                            >
                                <a
                                    href={
                                        generatePdpUrl(
                                            product,
                                            baseFilterSetup.alias === 'carBaseFilter' && baseFilterSetup.showAsCompanyCars,
                                            priceType === 'BusinessLeasing' || priceType === 'Leasing' ? 'Leasing' : 'Køb',
                                            (product as ProductCardDetails)?.category as 'Car' | 'Van'
                                        ) ?? undefined
                                    }
                                    style={{ textDecoration: 'none' }}
                                >
                                    <VehicleProductListCard
                                        productCard={product}
                                        className="keen-slider__slide"
                                        allowCarousel={false}
                                        isAboveFold={content.isAboveFold}
                                        priceType={priceType}
                                        locationBadge={location}
                                        isCompanyCar={(baseFilterSetup as CarBaseFilterSetup)?.showAsCompanyCars}
                                    />
                                </a>
                            </Link>
                        );
                    })}
                </VehicleProductCardCarousel>
            </VehicleProductRibbonWrapper>
            {departmentOptions.length > 0 ? (
                <BookTestDriveModal
                    vehicleItemNumber={selectedDemoVehicle?.itemNumber}
                    brand={selectedDemoVehicle?.brand}
                    brandModel={selectedDemoVehicle?.brandModel}
                    vehicleType={selectedDemoVehicle?.category}
                    name={selectedDemoVehicle?.name}
                    allDealerships={departmentOptions}
                    onClose={() => {
                        setBookTestDriveFormOpen(false);
                        applyScrollBars();
                    }}
                    visible={bookTestDriveFormOpen}
                    configurationId={content.filterConfiguration.key}
                    vehicleLocationId={selectedDemoVehicle?.locationId}
                    vehicleUrl={selectedDemoVehicle?.url ?? ''}
                    vehicleAvailability={selectedDemoVehicle?.availability}
                    testDriveAvailability="Available"
                    referenceNumber={selectedDemoVehicle?.referenceNumber}
                    vehicleEntityId={selectedDemoVehicle?.entityId ?? ''}
                />
            ) : null}
        </>
    );
};
