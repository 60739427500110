import { FC, useCallback, useMemo, useRef, useState } from 'react';
import {
    BrandModel,
    Card,
    CardImageArea,
    CarImageWrapper,
    CarVariant,
    MoreVariantsIndicator,
    DetailsLink,
    CardInformationWrapper,
    PrimarySpecs,
    Spec,
    SpecValue,
    SpecKey,
    ExtraEquipment,
    StyledBadge,
    StyledBadgeText,
    StyledColorPreview,
    BadgeContainer,
    CampaignLabelsWrapper,
    StyledAutoDesktopLabel,
    StyledLabelsWrapper,
    StyledLabel,
    StyledProductCardCta,
    StyledCampaignsList,
    StyledLabelContainer,
    StyledDimensionsWrapper,
} from './vehicle-product-list-card.styled';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { hesselViewModels } from '../../../../lib/view-models';
import { CompactCarousel } from '../../../shared/carousels/compact-carousel/compact-carousel.component';
import { SvgIcon } from '../../../shared/svg-icon';
import reactStringReplace from 'react-string-replace';
import { isNullOrEmpty, storeProductCardScrollToId } from '../../../../utils/helpers';
import { VehicleProductListMarketingCard } from '../../../vehicle/vehicle-product-list/vehicle-product-list-marketingcard';
import { ProductListCardPrice } from './product-list-card-price/product-list-card-price.component';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { PriceType } from '../../../../lib/api/models/umbraco/content-spot';
import { CampaignList } from '../../../shared/campaign-list/campaign-list.component';
import { ToolTipContent } from '../../../shared/tool-tip-content/tool-tip-content.component';
import { filterCampaignsForProductList } from '../../../../utils/helpers/campaign-filter.helpers';

type VehicleProductListCardProps = {
    className?: string;
    productCard: hesselViewModels.ProductCard;
    onCardClick?: () => void;
    ctaSettings?: {
        text: string;
        onClick: (args: string) => void;
    };
    nonScrollableId?: string;
    allowCarousel?: boolean;
    isAboveFold?: boolean;
    locationBadge?: string;
    priceType?: PriceType;
    isCompanyCar?: boolean; // Temp solution for EJH-4916 until we have proper taxation
};

export const VehicleProductListCard: FC<VehicleProductListCardProps> = ({
    className,
    productCard,
    onCardClick,
    ctaSettings,
    nonScrollableId,
    allowCarousel = true,
    isAboveFold = false,
    locationBadge,
    priceType,
    isCompanyCar,
}) => {
    const { resetProductDetailsStore } = ProductDetailsStore.useStoreActions((actions) => actions);
    const isMobile = useMediaQuery({ target: 'tablet' });
    const cardTitle = useRef<HTMLParagraphElement>(null);
    const [showLengthToolTip, setShowLengthToolTip] = useState(false);
    const [showHeightToolTip, setShowHeightToolTip] = useState(false);

    const pricesToDisplay = useMemo(() => {
        if (productCard.type !== 'detailed') return [];
        if (priceType && priceType === 'Purchase') {
            if (productCard.availability === 'Engros') {
                return productCard.price.filter((x) => x.type === 'Engros');
            } else {
                return productCard.price.filter((x) => x.type === 'Cash' || x.type === 'Financing');
            }
        }
        if (priceType && priceType === 'Taxation') return productCard.price.filter((x) => x.type === 'SolutionTaxation');
        if (priceType && priceType === 'BusinessLeasing') {
            return productCard.price.filter((x) => x.type === 'Operational leasing' || x.type === 'Financial leasing');
        }

        return productCard.price.filter(
            (x) =>
                x.type !== 'Cash' &&
                x.type !== 'Financing' &&
                x.type !== 'SolutionTaxation' &&
                x.type !== 'Operational leasing' &&
                x.type !== 'Financial leasing'
        );
    }, [priceType, productCard]);

    const priceTypeChecked = useMemo(() => {
        if (productCard.type !== 'detailed') return 'Leasing';
        if (productCard.availability === 'Engros') return 'Køb';
        if (priceType && priceType === 'Purchase') return 'Køb';
        if (priceType && priceType === 'Taxation') return 'Køb';
        if (priceType && priceType === 'BusinessLeasing') return 'Leasing';
        return 'Leasing';
    }, [priceType, productCard]);

    const formatMeasurement = useCallback((measurement: number) => `${(measurement / 1000).toFixed(2)}m`, []);

    const filteredCampaignLabels = useMemo(() => {
        if (productCard.type !== 'detailed') return [];
        return filterCampaignsForProductList(productCard.generalCampaignLabels, priceTypeChecked, isCompanyCar);
    }, [priceTypeChecked, productCard, isCompanyCar]);

    if (productCard.type === 'marketing') {
        return (
            <VehicleProductListMarketingCard
                header={productCard.header}
                description={productCard.description}
                backgroundImage={productCard.backgroundImage}
                backgroundColor={productCard.backgroundColor}
                textColor={productCard.textColor}
                cta={productCard.cTA ? productCard.cTA[0] : undefined}
                isAboveFold={isAboveFold}
            />
        );
    }

    if (productCard.type === 'sendRequest') {
        return null;
    }

    const ToolTipContentUI = (
        <>
            {productCard.length ? <StyledDimensionsWrapper>Længde: {formatMeasurement(productCard.length)}</StyledDimensionsWrapper> : null}
            {productCard.height ? <StyledDimensionsWrapper>Højde: {formatMeasurement(productCard.height)}</StyledDimensionsWrapper> : null}
        </>
    );

    const LengthToolTipUI =
        !productCard.length && !productCard.height ? null : (
            <ToolTipContent disableMinWidth={true} isOpen={showLengthToolTip} showIcon={false}>
                {ToolTipContentUI}
            </ToolTipContent>
        );

    const HeightToolTipUI =
        !productCard.length && !productCard.height ? null : (
            <ToolTipContent disableMinWidth={true} isOpen={showHeightToolTip} showIcon={false}>
                {ToolTipContentUI}
            </ToolTipContent>
        );

    return (
        <Card
            className={className}
            id={nonScrollableId && !isNullOrEmpty(nonScrollableId) ? nonScrollableId : productCard.id}
            autoDesktopCampaign={productCard.autoDesktopCampaign === true}
        >
            {productCard.autoDesktopCampaign === true ? <StyledAutoDesktopLabel>Populær</StyledAutoDesktopLabel> : null}
            <DetailsLink
                clickable={productCard.availability !== 'Demo'}
                onClick={
                    productCard.availability !== 'Demo'
                        ? () => {
                              if (onCardClick) {
                                  onCardClick();
                              } else {
                                  storeProductCardScrollToId(`${productCard.id}__${window.location.pathname.toLowerCase()}`);
                                  resetProductDetailsStore();
                              }
                          }
                        : undefined
                }
            >
                <CardImageArea>
                    <BrandModel ref={cardTitle}>{productCard.brandModel}</BrandModel>

                    <CarVariant
                        usedCar={productCard.availability === 'Used' || productCard.category === 'Van'}
                        widthPx={cardTitle.current?.clientWidth ?? 290}
                    >
                        {productCard.carVariant}
                    </CarVariant>

                    <StyledLabelsWrapper>
                        {productCard.reserved ? (
                            <StyledLabelContainer backgroundColor="#0B0B0B">
                                <StyledLabel textColor="#FCFCFC">Reserveret</StyledLabel>
                            </StyledLabelContainer>
                        ) : null}

                        {productCard.lenghtType ? (
                            <StyledLabelContainer
                                onMouseEnter={(e) => {
                                    e.stopPropagation();
                                    setShowLengthToolTip(true);
                                }}
                                onMouseLeave={(e) => {
                                    e.stopPropagation();
                                    setShowLengthToolTip(false);
                                }}
                                backgroundColor={'#0B0B0B'}
                            >
                                {LengthToolTipUI}
                                <StyledLabel textColor="white">{productCard.lenghtType}</StyledLabel>
                            </StyledLabelContainer>
                        ) : null}

                        {productCard.heightType ? (
                            <StyledLabelContainer
                                onMouseEnter={(e) => {
                                    e.stopPropagation();
                                    setShowHeightToolTip(true);
                                }}
                                onMouseLeave={(e) => {
                                    e.stopPropagation();
                                    setShowHeightToolTip(false);
                                }}
                                backgroundColor={'#688488'}
                            >
                                {HeightToolTipUI}
                                <StyledLabel textColor="white">{productCard.heightType}</StyledLabel>
                            </StyledLabelContainer>
                        ) : null}
                    </StyledLabelsWrapper>

                    {isMobile && allowCarousel && productCard.imageResources.length > 1 ? (
                        <CompactCarousel slides={productCard.imageResources} isAboveFold={isAboveFold} />
                    ) : (
                        <CarImageWrapper usedCard={productCard.availability === 'Used' || productCard.availability === 'Engros'}>
                            {productCard.primaryImage ? (
                                <img src={productCard.primaryImage?.url} alt={productCard.carVariant} loading={isAboveFold ? 'eager' : 'lazy'} />
                            ) : (
                                <p style={{ marginTop: '30px', opacity: '.5' }}>Billede på vej</p>
                            )}
                        </CarImageWrapper>
                    )}

                    {productCard.badge ||
                    locationBadge ||
                    (productCard.otherColors && productCard.otherColors?.length > 0) ||
                    filteredCampaignLabels.length > 0 ? (
                        <BadgeContainer>
                            {productCard.badge ? (
                                <StyledBadge color="Primary">
                                    <StyledBadgeText>{productCard.badge}</StyledBadgeText>
                                </StyledBadge>
                            ) : null}

                            <CampaignLabelsWrapper>
                                {locationBadge ? (
                                    <StyledCampaignsList
                                        campaigns={[
                                            {
                                                endDateTime: '',
                                                startDateTime: '',
                                                style: 'BlackWhite',
                                                text: locationBadge,
                                                purchaseTypes: [],
                                            },
                                        ]}
                                    />
                                ) : null}
                                {filteredCampaignLabels.length > 0 ? (
                                    <CampaignList campaigns={filteredCampaignLabels} visibleAmount={productCard.availability === 'Demo' ? 2 : 3} />
                                ) : null}
                            </CampaignLabelsWrapper>
                            {productCard.otherColors ? (
                                <MoreVariantsIndicator>
                                    {productCard.otherColors.map((x, idx) => {
                                        return <StyledColorPreview src={x} key={`extravariant-${idx}`} />;
                                    })}
                                    {productCard.additionalColors > 0 ? `+${productCard.additionalColors.toString()}` : null}
                                </MoreVariantsIndicator>
                            ) : null}
                        </BadgeContainer>
                    ) : (
                        <BadgeContainer />
                    )}
                </CardImageArea>
                <CardInformationWrapper>
                    <PrimarySpecs>
                        {productCard.keySpecs.map((x, idx) => {
                            const specValue = reactStringReplace(x.value, '{{checkmark}}', (_, index) => (
                                <SvgIcon key={`icon-${index}`} iconName="checkmark"></SvgIcon>
                            ));
                            return (
                                <Spec key={`primaryspec-${idx}`}>
                                    <SpecValue>{specValue}</SpecValue>
                                    <SpecKey>{x.key}</SpecKey>
                                </Spec>
                            );
                        })}
                    </PrimarySpecs>
                    <ExtraEquipment>
                        {productCard.availability === 'InStock' || productCard.availability === 'Used' ? (
                            productCard.extraSpecs?.length > 0 ? (
                                <p>
                                    {!isNullOrEmpty(productCard.extraEquipmentLabel) ? productCard.extraEquipmentLabel : 'Inkl. ekstraudstyr bl.a.'}
                                </p>
                            ) : null
                        ) : (
                            <p>Øvrige specifikationer</p>
                        )}
                        <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5px', rowGap: '5px' }}>
                            {productCard.extraSpecs.map((x, idx) => {
                                return (
                                    <div style={{ backgroundColor: 'rgb(226, 226, 228)', padding: '5px' }} key={`extraspecs-${idx}`}>
                                        <p style={{ fontSize: '10px', lineHeight: '10px', whiteSpace: 'nowrap' }}>{x}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </ExtraEquipment>
                </CardInformationWrapper>
            </DetailsLink>

            {/* If we have a demo car, we should not show prices */}
            {productCard.availability !== 'Demo' ? <ProductListCardPrice prices={pricesToDisplay} /> : null}

            {ctaSettings ? (
                <StyledProductCardCta
                    variant="primary"
                    onClick={(e) => {
                        e.stopPropagation();
                        ctaSettings.onClick(productCard.id);
                    }}
                >
                    {ctaSettings.text}
                </StyledProductCardCta>
            ) : null}
        </Card>
    );
};

export default VehicleProductListCard;
