import { FC, useMemo, useState } from 'react';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import {
    StyledCheckboxLabel,
    StyledCta,
    StyledDivider,
    StyledFormHeader,
    StyledInputsWrapper,
    StyledLeasingInformationTable,
    StyledLeasingInformationTableRow,
    StyledModalHeader,
    StyledModalSubHeader,
    StyledPriceInfoText,
    StyledTextWithDisclaimer,
} from './company-lead-modal-form.styled';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { formatPrice, validateEmail, validateName, validateNotEmpty, validateTel, validateZip, sanitizeMarkup } from '../../../../utils/helpers';
import { Abbreviations } from '../../../../constants/units-and-abbreviations';
import { TextInput } from '../../../forms/inputs';
import { CheckBox } from '../../../shared';
import { usePdpLeadSubmitData } from '../../hooks/use-pdp-lead-submit-data';
import { submitLeadData } from '../../../../lib/api/lead/lead-api';
import { OrderCarModalSubmitData } from '../../hooks/financial-types';
import { GeneralFormSubmitReceipt } from '../../../shared/general-form-submit-receipt/general-form-submit-receipt.component';
import { getCashPriceForAllEquipment, getPriceForAllEquipment } from '../../../../lib/state/hessel-site/product-details/price.helper';

type IProps = {
    onClose: () => void;
    visible: boolean;
    personalDataPolicyLabel: string;
};

type CompanyLeasingLeadForm = {
    email: string;
    phone: string;
    fullName: string;
    companyName: string;
    termsAccepted?: boolean;
    url: string;
    canValidate: boolean;
    postalCode: string;
};

export const CompanyLeadModalForm: FC<IProps> = ({ onClose, visible, personalDataPolicyLabel }) => {
    const { selectedTab, selectedProduct } = ProductDetailsStore.useStoreState((state) => state);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formValues, setFormValues] = useState<CompanyLeasingLeadForm>({
        email: '',
        phone: '',
        fullName: '',
        termsAccepted: false,
        url: selectedProduct?.url ?? '',
        canValidate: false,
        companyName: '',
        postalCode: '',
    });

    const selectedLeasingInfo = useMemo(() => {
        if (selectedTab === 'Finansiel Leasing') {
            return selectedProduct?.purchaseTypes.financialLeasing;
        } else if (selectedTab === 'Operationel Leasing') {
            return selectedProduct?.purchaseTypes.operationalLeasing;
        }
    }, [selectedProduct, selectedTab]);

    const { getOrderCarModalSubmitData } = usePdpLeadSubmitData();

    const resetFormData = () => {
        setFormValues({
            email: '',
            phone: '',
            fullName: '',
            termsAccepted: false,
            url: selectedProduct?.url ?? '',
            canValidate: false,
            companyName: '',
            postalCode: '',
        });
        setFormSubmitted(false);
    };

    const onFormSubmit = async () => {
        if (
            validateEmail(formValues?.email ?? '') &&
            validateTel(formValues?.phone ?? '') &&
            validateName(formValues?.fullName ?? '') &&
            validateZip(formValues?.postalCode ?? '') &&
            validateNotEmpty(formValues?.companyName ?? '') &&
            formValues.termsAccepted
        ) {
            const submitData = getOrderCarModalSubmitData({ ...formValues, consentCode: personalDataPolicyLabel });
            await submitLeadData<OrderCarModalSubmitData>('solutions-lead', submitData);
            setFormSubmitted(true);
        } else {
            setFormValues({ ...formValues, canValidate: true });
        }
    };

    return (
        <SidePanel
            isVisible={visible}
            cancelAction={() => {
                onClose();
                resetFormData();
            }}
            variant="sm"
        >
            <SidePanelLayout
                closeSidePanel={() => {
                    onClose();
                    resetFormData();
                }}
            >
                {formSubmitted ? (
                    <div>
                        <GeneralFormSubmitReceipt
                            title={'Tak for din forespørsel'}
                            description={'Vi vil kontakte dig hurtigst muligt'}
                            buttonText={'Luk'}
                            onCtaClick={() => {
                                onClose();
                                resetFormData();
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        {selectedLeasingInfo ? (
                            <>
                                <StyledModalHeader>Din erhvervsleasingaftale</StyledModalHeader>
                                <StyledModalSubHeader>Herunder kan du se en udspecificering af din erhvervsleasingaftale</StyledModalSubHeader>
                                <StyledLeasingInformationTable>
                                    <StyledLeasingInformationTableRow>
                                        <strong>Leasingtype</strong>
                                        <span>{selectedTab === 'Finansiel Leasing' ? 'Finansiel' : 'Operationel'}</span>
                                    </StyledLeasingInformationTableRow>
                                    <StyledLeasingInformationTableRow>
                                        <strong>Månedlig ydelse</strong>
                                        <StyledTextWithDisclaimer>{`${formatPrice(
                                            selectedLeasingInfo.baseMonthlyPrice +
                                                (selectedProduct ? getPriceForAllEquipment(selectedProduct, selectedTab) ?? 0 : 0)
                                        )} ${Abbreviations.KR_SLASH_MD}`}</StyledTextWithDisclaimer>
                                    </StyledLeasingInformationTableRow>
                                    <StyledLeasingInformationTableRow>
                                        <strong>Løbetid</strong>
                                        <span>{`${selectedLeasingInfo.durationMonths} ${Abbreviations.MDR}`}</span>
                                    </StyledLeasingInformationTableRow>
                                    <StyledLeasingInformationTableRow>
                                        <strong>Kilometer pr. år</strong>
                                        <span>{`${formatPrice(selectedLeasingInfo.kilometersPerYear / (selectedLeasingInfo.durationMonths / 12))} ${
                                            Abbreviations.KM_SLASH_AAR
                                        }`}</span>
                                    </StyledLeasingInformationTableRow>
                                    <StyledLeasingInformationTableRow>
                                        <strong>Udbetaling</strong>
                                        <StyledTextWithDisclaimer>{`${formatPrice(selectedLeasingInfo.downPayment)} ${
                                            Abbreviations.KR
                                        }`}</StyledTextWithDisclaimer>
                                    </StyledLeasingInformationTableRow>
                                    {selectedTab === 'Finansiel Leasing' && selectedProduct?.purchaseTypes.financialLeasing.residualValue && (
                                        <StyledLeasingInformationTableRow>
                                            <strong>Restværdi</strong>
                                            <StyledTextWithDisclaimer>{`${formatPrice(
                                                selectedProduct.purchaseTypes.financialLeasing.residualValue
                                            )} ${Abbreviations.KR}`}</StyledTextWithDisclaimer>
                                        </StyledLeasingInformationTableRow>
                                    )}
                                    {selectedProduct?.purchaseTypes.solutionTaxation?.basePrice && (
                                        <StyledLeasingInformationTableRow>
                                            <strong>Beskatningsgrundlag</strong>
                                            <span>{`${formatPrice(
                                                selectedProduct.purchaseTypes.solutionTaxation?.basePrice +
                                                    (getCashPriceForAllEquipment(selectedProduct, selectedTab) ?? 0)
                                            )} ${Abbreviations.KR}`}</span>
                                        </StyledLeasingInformationTableRow>
                                    )}
                                    <StyledPriceInfoText>
                                        {selectedTab === 'Finansiel Leasing'
                                            ? '* Priser er ekskl. moms. Restværdi er desuden også ekskl. afgift og vist uden eventuelle tilvalg af ekstraudstyr. Den endelige restværdi vil fremgå af din leasingkontrakt.'
                                            : '* Priser er ekskl. moms.'}
                                    </StyledPriceInfoText>
                                </StyledLeasingInformationTable>
                                <StyledDivider />
                            </>
                        ) : null}
                        <StyledFormHeader>Udfyld formularen og få tilsendt et tilbud</StyledFormHeader>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setFormValues({
                                    ...formValues,
                                    canValidate: true,
                                });
                                onFormSubmit();
                            }}
                        >
                            <StyledInputsWrapper>
                                <TextInput
                                    id="full-name-input"
                                    label={'Navn'}
                                    onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            fullName: e.target.value,
                                        });
                                    }}
                                    type="text"
                                    placeholder={`f.eks. 'Hans Hansen'`}
                                    value={formValues?.fullName}
                                    disabled={false}
                                    isValid={validateName(formValues?.fullName ?? '')}
                                    canValidateInputField={!!formValues?.canValidate}
                                    validationMessage={'Venligst indtast dit fulde navn'}
                                    required={true}
                                />
                                <TextInput
                                    id="company-name-input"
                                    label={'Firmanavn'}
                                    onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            companyName: e.target.value,
                                        });
                                    }}
                                    type="text"
                                    placeholder={`f.eks. 'Ejner Hessel'`}
                                    value={formValues?.companyName}
                                    disabled={false}
                                    isValid={validateNotEmpty(formValues?.companyName ?? '')}
                                    canValidateInputField={!!formValues?.canValidate}
                                    validationMessage={'Venligst indtast dit firmanavn'}
                                    required={true}
                                />
                                <TextInput
                                    id="zip-input"
                                    label={'Post nr.'}
                                    onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            postalCode: e.target.value,
                                        });
                                    }}
                                    type="text"
                                    placeholder={`f.eks. '8000'`}
                                    value={formValues?.postalCode}
                                    disabled={false}
                                    isValid={validateZip(formValues?.postalCode ?? '')}
                                    canValidateInputField={!!formValues?.canValidate}
                                    validationMessage={'Venligst indtast dit postnummer'}
                                    required={true}
                                />
                                <TextInput
                                    id="email-input"
                                    label={'E-mail'}
                                    onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            email: e.target.value,
                                        });
                                    }}
                                    type="email"
                                    placeholder={`f.eks. 'hans@mail.dk'`}
                                    value={formValues?.email}
                                    disabled={false}
                                    isValid={validateEmail(formValues?.email ?? '')}
                                    canValidateInputField={!!formValues?.canValidate}
                                    validationMessage={'Venligst indtast en gyldig e-mailadresse'}
                                    required={true}
                                />

                                <TextInput
                                    id="phone-input"
                                    label={'Tlf.nr.'}
                                    onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            phone: e.target.value,
                                        });
                                    }}
                                    type="tel"
                                    placeholder={`f.eks. '1234 5678'`}
                                    value={formValues?.phone}
                                    disabled={false}
                                    isValid={validateTel(formValues?.phone ?? '')}
                                    canValidateInputField={!!formValues?.canValidate}
                                    validationMessage={'Venligst indtast et gyldigt telefonnummer'}
                                    required={true}
                                />
                            </StyledInputsWrapper>
                            <StyledDivider />
                            <CheckBox
                                id={`accept-terms`}
                                value="1"
                                checked={!!formValues?.termsAccepted}
                                onChange={(checked) =>
                                    setFormValues({
                                        ...formValues,
                                        termsAccepted: checked,
                                    })
                                }
                                textAlign="top"
                                isValid={formValues?.termsAccepted}
                                required={true}
                                validationMessage={'Du bedes acceptere vores persondatapolitik, for at kunne indhente et tilbud.'}
                                canValidate={!!formValues?.canValidate}
                            >
                                <StyledCheckboxLabel {...sanitizeMarkup(personalDataPolicyLabel)} />
                            </CheckBox>
                            <StyledCta variant="primary" type="submit" onClick={() => null}>
                                ANMOD OM TILBUD
                            </StyledCta>
                        </form>
                    </div>
                )}
            </SidePanelLayout>
        </SidePanel>
    );
};
