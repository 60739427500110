import { VFC } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { ProductDetailsSpecificationsDialog } from '../../../lib/api/models/umbraco/product-details';
import { hesselViewModels } from '../../../lib/view-models';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import {
    StyledCompactCarousel,
    StyledSpecsDialogCarousel,
    StyledSpecsDialogDisclaimer,
    StyledSpecsDialogHeading,
    StyledSpecsDialogLabel,
    StyledSpecsDialogTable,
    StyledSpecsDialogTableBody,
    StyledSpecsDialogTableRow,
    StyledSpecsDialogTitle,
    StyledSpecsDialogValue,
} from './specifications-dialog.styled';
import { ToolTipContent } from '../../shared/tool-tip-content/tool-tip-content.component';

type Specification = hesselViewModels.MappedSpecification;

type SpecificationGroup = {
    heading: string;
    images: string[];
    specifications: Array<Specification | null>;
};

export type SpecificationsDialogProps = {
    content: ProductDetailsSpecificationsDialog;
    isVisible: boolean;
    onClose: () => void;
    specificationGroups: Array<SpecificationGroup>;
    images: Array<{
        caption: string;
        image: sharedTypes.Image;
    }>;
};

export const SpecificationsDialog: VFC<SpecificationsDialogProps> = (props) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    const renderSpec = (spec: Specification | null) => {
        const toolTip = props.content.specificationTooltips?.find((x) => x.label === spec?.label);
        return spec ? (
            <StyledSpecsDialogTableRow key={spec.label}>
                <StyledSpecsDialogLabel>
                    {spec.label}
                    {toolTip && <ToolTipContent openDirection="right">{toolTip.tooltip}</ToolTipContent>}
                </StyledSpecsDialogLabel>
                <StyledSpecsDialogValue>
                    {spec.value} {spec.unit}
                </StyledSpecsDialogValue>
            </StyledSpecsDialogTableRow>
        ) : null;
    };

    const renderGroup = (group: SpecificationGroup) => (
        <div key={group.heading} role="presentation none">
            <StyledSpecsDialogHeading>{group.heading}</StyledSpecsDialogHeading>
            <StyledSpecsDialogTable>
                <StyledSpecsDialogTableBody>{group.specifications?.filter(Boolean).map(renderSpec)}</StyledSpecsDialogTableBody>
            </StyledSpecsDialogTable>
        </div>
    );

    const renderCarousel = () => {
        if (!props.images || props.images.length === 0) {
            return null;
        }

        return isMobile ? (
            <StyledCompactCarousel
                slides={props.images.map((x) => {
                    return {
                        id: x.image.id,
                        type: 'image',
                        url: MEDIA_URL + x.image.src,
                        caption: x.caption,
                    };
                })}
                isAboveFold={false}
                useSquaredCorners={props.content.useSquaredCornersForImages}
            />
        ) : (
            <StyledSpecsDialogCarousel
                slides={props.images.map((x) => ({
                    url: MEDIA_URL + x.image.src,
                    type: 'image',
                    caption: x.caption,
                }))}
                isAboveFold={false}
                useSquaredCorners={props.content.useSquaredCornersForImages}
            />
        );
    };

    return (
        <SidePanel cancelAction={props.onClose} isVisible={props.isVisible}>
            <SidePanelLayout closeSidePanel={props.onClose}>
                <StyledSpecsDialogTitle>{props.content.title}</StyledSpecsDialogTitle>

                {props.specificationGroups.map(renderGroup)}

                {renderCarousel()}

                <StyledSpecsDialogDisclaimer>{props.content.disclaimer}</StyledSpecsDialogDisclaimer>
            </SidePanelLayout>
        </SidePanel>
    );
};
