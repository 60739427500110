import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { SidebarNames } from '../../../gtm-tracking/hooks/hire-lead-checkout-events';
import { useGtmTracking } from '../../../gtm-tracking/hooks/use-gtm-tracking';
import { useScrollLock } from '../../../hooks/use-scroll-lock';

export enum OpenDialog {
    Benefits,
    BookTrial,
    Form,
    Media,
    None,
    PaymentPlans,
    Specifications,
    ContactHessel,
    BookVanAdvisor,
    BookCompanyCarAdvisor,
    CompareVansLeasingTypes,
    CompareCarsLeasingTypes,
    FinancialCalculations,
    OrderCarFormModal,
    EnquireAboutCarModal,
    AutoProff,
    AutoProffExplainer,
    EngrosExplainer,
    CompanyLeasing,
}

type ProductDetailsPageContextState = {
    openedDialog: OpenDialog;
};

type ProductDetailsPageContextActions = {
    openDialog: (options: { dialog?: OpenDialog; sidebarOpened?: SidebarNames }) => void;
    closeModal: () => void;
};

/**
 * Context for the product details page for controlling modals
 */
const ProductDetailsPageContext = createContext<ProductDetailsPageContextState & ProductDetailsPageContextActions>({
    openedDialog: OpenDialog.None,
    openDialog: () => {},
    closeModal: () => {},
});

export const ProductDetailsPageModalsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { applyScrollBars, hideScrollBars } = useScrollLock();
    const [openedDialog, setOpenedDialog] = useState<OpenDialog>(OpenDialog.None);
    const { trackHireLeadCheckout } = useGtmTracking();
    const leadCheckoutTracker = trackHireLeadCheckout();

    const openDialog = ({ dialog, sidebarOpened }: { dialog?: OpenDialog; sidebarOpened?: SidebarNames }) => {
        hideScrollBars();
        dialog !== undefined && setOpenedDialog(dialog);
        !!sidebarOpened && leadCheckoutTracker.sidebarOpened(sidebarOpened);
    };

    const closeModal = () => {
        setOpenedDialog(OpenDialog.None);
        applyScrollBars();
    };

    return <ProductDetailsPageContext.Provider value={{ openedDialog, openDialog, closeModal }}>{children}</ProductDetailsPageContext.Provider>;
};

export const usePdpModals = (): ProductDetailsPageContextState & ProductDetailsPageContextActions => {
    const pdpContext = useContext(ProductDetailsPageContext);

    if (!pdpContext) {
        throw Error('The fineflow context needs to be used inside the FineFlowState provider');
    }

    return pdpContext;
};
