import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { ChevronDown, Close } from '../../icons';
import { IconButton } from '../../shared/icon-button/icon-button.component';

export const StickyMobileSummaryContainer = styled.section<{ visible: boolean; expanded: boolean }>`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    isolation: isolate;
    z-index: 24;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-top: 1px solid #e2e2e4;
    border-bottom: 1px solid #e2e2e4;
    transition: height 0.3s cubic-bezier(0.4, 0.25, 0.25, 1), transform 0.2s ease-in-out, opacity 0.1s ease-in-out;
    height: ${(props) => (props.expanded ? '80vh' : '84px')};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(100%)')};
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.visible ? 1 : 0)};

    @media ${device.tablet} {
        display: none;
    }
`;

export const OwnershipContainer = styled.div`
    position: absolute;
    width: 100%;
    top: -25px;
    left: 20px;
    display: flex;
    z-index: 2;
    max-width: 500px;
`;

export const StyledIconButton = styled(IconButton)`
    color: ${(props) => props.theme.palette.common.offWhite};
    background-color: ${(props) => props.theme.palette.primary.main};

    &:active {
        background-color: ${(props) => props.theme.palette.primary.main};
    }

    & svg {
        width: 24px;
        height: 24px;
    }
`;

export const StyledChevronDown = styled(ChevronDown)`
    transform: rotateX(180deg);
    & path {
        stroke: ${(props) => props.theme.palette.common.offWhite};
    }
`;

export const InfoContainer = styled.div<{ expanded: boolean; isScrolledDown: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #ffffff;
    transition: height 0.2s ease-in;
    overflow: hidden;
    height: ${(props) => (props.expanded ? (!props.isScrolledDown ? '0px' : '84px') : '84px')};
    border-bottom: ${(props) => (props.expanded ? (!props.isScrolledDown ? '0px' : '1px solid #e2e2e4') : '0px')};
    position: ${(props) => (props.expanded ? (!props.isScrolledDown ? 'absolute' : 'absolute') : 'initial')};
    z-index: 1;
`;

export const InfoContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const Price = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 18px;
    text-align: right;
`;

export const Value = styled.p`
    color: #636363;
    font-size: 12px;
    letter-spacing: 0;
    font-weight: normal;
    line-height: 18px;
    text-align: right;
`;

export const Title = styled.p`
    color: #0b0b0b;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 600;
    line-height: 18px;
`;

export const SubTitle = styled.p`
    color: #767676;
    font-size: 12px;
    letter-spacing: 0;
    font-weight: normal;
    line-height: 18px;
`;

export const DimmedPriceText = styled.p`
    color: #767676;
    font-size: 12px;
    letter-spacing: 0;
    font-weight: 500;
    text-align: right;
    line-height: 18px;
`;

export const CloseButton = styled(IconButton)`
    position: absolute;
    top: -35px;
    right: 24px;
    color: ${(props) => props.theme.palette.common.offWhite};
    background-color: ${(props) => props.theme.palette.primary.main};
    z-index: 10;

    &:active {
        background-color: ${(props) => props.theme.palette.primary.main};
    }
`;

export const StyledClose = styled(Close)`
    width: 24px !important;
    height: 24px !important;
    & path {
        stroke: ${(props) => props.theme.palette.common.offWhite};
    }
`;

export const ExpandedContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
`;
