import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    SubTitle,
    DimmedPriceText,
    InfoContainer,
    StickyMobileSummaryContainer,
    StyledChevronDown,
    StyledIconButton,
    Price,
    Value,
    Title,
    OwnershipContainer,
    CloseButton,
    StyledClose,
    ExpandedContainer,
    InfoContent,
} from './sticky-mobile-summary.styled';
import { PriceByOwnership } from '../hooks/price-by-ownership.types';
import { formatPrice } from '../../../utils/helpers';
import { Abbreviations } from '../../../constants/units-and-abbreviations';
import { hesselViewModels } from '../../../lib/view-models';
import { useScrollLock } from '../../../hooks/use-scroll-lock';
import { PdpCarFinanceSetup, PdpVanFinanceSetup } from '../../../lib/api/models/umbraco/product-details';
import { CarPurchaseOwnershipCashTab } from '../../../lib/api/models/umbraco/product-types/cars.types';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { umbraco } from '../../../lib/api';
import { VanPurchaseOwnershipCashTab } from '../../../lib/api/models/umbraco/product-types/vans.types';
import { Backdrop } from '../../shared/backdrop';

type StickyMobileSummaryProps = {
    visible: boolean;
    ownershipToggle?: React.ReactNode;
    priceInformation?: PriceByOwnership;
    selectedOwnershipTab?: hesselViewModels.OwnershipTab;
    carFinancialSetup: Array<PdpCarFinanceSetup>;
    vanFinancialSetup?: Array<PdpVanFinanceSetup>;
    product?: hesselViewModels.ProductDetails;
    page?: umbraco.ProductDetailsPage;
    carHireLeasingPeriodDescription?: string;
    expandedChildren?: React.ReactNode;
};

const StickyMobileSummaryMemo = (props: StickyMobileSummaryProps) => {
    const {
        visible,
        ownershipToggle,
        priceInformation,
        selectedOwnershipTab,
        carFinancialSetup,
        vanFinancialSetup,
        product,
        page,
        carHireLeasingPeriodDescription,
        expandedChildren,
    } = props;
    const { price, monthly, overrideText } = priceInformation || {};
    const [isExpanded, setIsExpanded] = useState(false);
    const { hideScrollBars, applyScrollBars } = useScrollLock();
    const { isAFromPrice } = ProductDetailsStore.useStoreState((state) => state);

    const [isScrolledDown, setIsScrolledDown] = useState(false);
    const expandedContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = useCallback(() => {
        if (expandedContainerRef.current) {
            const scrollTop = expandedContainerRef.current.scrollTop;
            setIsScrolledDown(scrollTop > 100);
        }
    }, []);

    useEffect(() => {
        const container = expandedContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    const handleDocumentKeyDown = useCallback(
        (e) => {
            if (e.key === 'Escape') {
                setIsExpanded(false);
                applyScrollBars();
            }
        },
        [setIsExpanded, applyScrollBars]
    );

    useEffect(() => {
        if (visible) {
            document.addEventListener('keydown', handleDocumentKeyDown, { passive: true });
            const container = expandedContainerRef.current;
            if (container) {
                container.addEventListener('scroll', handleScroll);
            }
        } else {
            document.removeEventListener('keydown', handleDocumentKeyDown);
            const container = expandedContainerRef.current;
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        }

        return () => {
            document.removeEventListener('keydown', handleDocumentKeyDown);
        };
    }, [handleDocumentKeyDown, handleScroll, visible]);

    const priceInfoText = useMemo(() => {
        switch (selectedOwnershipTab) {
            case 'Car Kontant':
            case 'Car HiRE':
            case 'Beskatningsgrundlag':
            case 'Van Kontant':
            case 'Van Finansiering':
                return priceInformation?.priceTextOverride;
            case 'Car Finansiering':
            case 'Car Privat Leasing':
            case 'Finansiel Leasing':
            case 'Operationel Leasing':
            default:
                return '';
        }
    }, [priceInformation, selectedOwnershipTab]);

    const [titleText, subTitleText] = useMemo(() => {
        switch (selectedOwnershipTab) {
            case 'Car Kontant': {
                const carCashTabCms = carFinancialSetup.find((x) => x.alias === 'carPurchaseOwnershipCashTab') as CarPurchaseOwnershipCashTab;
                return [carCashTabCms?.tabName ?? '', carCashTabCms.subHeader ?? ''];
            }

            case 'Car Finansiering': {
                const financeTabCms = carFinancialSetup.find((x) => x.alias === 'carPurchaseOwnershipFinanceTab');
                return [
                    financeTabCms?.tabName ?? '',
                    product?.purchaseTypes.financing.durationMonths ? `${product?.purchaseTypes.financing.durationMonths} ${Abbreviations.MDR}` : '',
                ];
            }

            case 'Car HiRE': {
                return [page?.conceptLabel, carHireLeasingPeriodDescription];
            }

            case 'Car Privat Leasing': {
                const carPrivateLeasingCms = carFinancialSetup.find((x) => x.alias === 'carLeasingOwnershipPrivateLeasingTab');

                return [
                    carPrivateLeasingCms?.tabName ?? '',
                    product?.purchaseTypes.privateLeasing.durationMonths
                        ? `${product?.purchaseTypes.privateLeasing.durationMonths} ${Abbreviations.MD}`
                        : '',
                ];
            }

            case 'Beskatningsgrundlag': {
                const companyCarCMS = carFinancialSetup.find((x) => x.alias === 'companyCar');
                return [companyCarCMS?.tabName ?? '', ''];
            }

            case 'Operationel Leasing': {
                const operationalLeasingCms =
                    product?.vehicleType === 'Van'
                        ? vanFinancialSetup?.find((x) => x.alias === 'vanLeasingOwnershipOperationalTab')
                        : carFinancialSetup?.find((x) => x.alias === 'carLeasingOwnershipOperationalTab');

                return [
                    operationalLeasingCms?.tabName ?? '',
                    product?.purchaseTypes.operationalLeasing.durationMonths
                        ? `${product?.purchaseTypes.operationalLeasing.durationMonths} ${Abbreviations.MDR}`
                        : 'Varialbel periode',
                ];
            }
            case 'Finansiel Leasing': {
                const financialLeasingCms =
                    product?.vehicleType === 'Van'
                        ? vanFinancialSetup?.find((x) => x.alias === 'vanLeasingOwnershipFinancialTab')
                        : carFinancialSetup?.find((x) => x.alias === 'carLeasingOwnershipFinancialTab');

                return [
                    financialLeasingCms?.tabName ?? '',
                    product?.purchaseTypes.financialLeasing.durationMonths
                        ? `${product?.purchaseTypes.financialLeasing.durationMonths} ${Abbreviations.MDR}`
                        : 'Varialbel periode',
                ];
            }

            case 'Van Kontant': {
                const cashTabCms = vanFinancialSetup?.find((x) => x.alias === 'vanPurchaseOwnershipCashTab') as VanPurchaseOwnershipCashTab;
                return [cashTabCms?.tabName ?? '', cashTabCms.subHeader ?? ''];
            }

            case 'Van Finansiering': {
                const financeTabCms = vanFinancialSetup?.find((x) => x.alias === 'vanPurchaseOwnershipFinanceTab');
                return [
                    financeTabCms?.tabName ?? '',
                    product?.purchaseTypes.financing.durationMonths ? `${product?.purchaseTypes.financing.durationMonths} ${Abbreviations.MDR}` : '',
                ];
            }

            default:
                return ['', ''];
        }
    }, [
        selectedOwnershipTab,
        carFinancialSetup,
        product?.purchaseTypes.financing.durationMonths,
        product?.purchaseTypes.privateLeasing.durationMonths,
        product?.purchaseTypes.operationalLeasing.durationMonths,
        product?.purchaseTypes.financialLeasing.durationMonths,
        product?.vehicleType,
        page?.conceptLabel,
        carHireLeasingPeriodDescription,
        vanFinancialSetup,
    ]);

    const showContanctForPrice = useMemo(() => {
        if (selectedOwnershipTab === 'Operationel Leasing' && product?.vehicleType === 'Car' && !product?.purchaseTypes.operationalLeasing.show) {
            return true;
        }
        if (selectedOwnershipTab === 'Finansiel Leasing' && product?.vehicleType === 'Car' && !product?.purchaseTypes.financialLeasing.show) {
            return true;
        }
        return false;
    }, [product?.purchaseTypes.financialLeasing.show, product?.purchaseTypes.operationalLeasing.show, product?.vehicleType, selectedOwnershipTab]);

    return (
        <>
            {isExpanded && (
                <Backdrop
                    hide={() => {
                        setIsExpanded(false);
                        applyScrollBars();
                    }}
                ></Backdrop>
            )}
            <StickyMobileSummaryContainer visible={visible} expanded={isExpanded}>
                {isExpanded && (
                    <CloseButton
                        onClick={() => {
                            setIsExpanded(false);
                            applyScrollBars();
                        }}
                        label="Luk"
                    >
                        <StyledClose></StyledClose>
                    </CloseButton>
                )}
                <OwnershipContainer>{ownershipToggle}</OwnershipContainer>
                <InfoContainer expanded={isExpanded} isScrolledDown={isScrolledDown}>
                    <InfoContent>
                        <div>
                            <Title>{titleText}</Title>
                            <SubTitle>{subTitleText}</SubTitle>
                        </div>
                        <div>
                            {showContanctForPrice ? (
                                <>
                                    <Price>{`Kontakt for pris`}</Price>
                                    <DimmedPriceText>{`Så får du et uforpligtende tilbud`}</DimmedPriceText>
                                </>
                            ) : (
                                <>
                                    {overrideText ? (
                                        <Value>{overrideText}</Value>
                                    ) : (
                                        <Price>
                                            {isAFromPrice ? 'Fra ' : null}
                                            {price ? formatPrice(price) : ''} {monthly ? Abbreviations.KR_SLASH_MD : Abbreviations.KR}
                                        </Price>
                                    )}

                                    {priceInformation?.downPayment ? (
                                        <DimmedPriceText>{`Udbetaling ${formatPrice(priceInformation.downPayment)} ${
                                            Abbreviations.KR
                                        }.`}</DimmedPriceText>
                                    ) : (
                                        <DimmedPriceText>{priceInfoText}</DimmedPriceText>
                                    )}
                                </>
                            )}
                        </div>
                    </InfoContent>
                    {!isExpanded && (
                        <StyledIconButton
                            label="Udvid"
                            onClick={() => {
                                setIsExpanded(true);
                                hideScrollBars();
                            }}
                        >
                            <StyledChevronDown></StyledChevronDown>
                        </StyledIconButton>
                    )}
                </InfoContainer>
                {isExpanded && (
                    <ExpandedContainer ref={expandedContainerRef}>
                        {/* <div style={{ height: 2000, width: '100%', backgroundColor: 'teal' }}></div> */}
                        {expandedChildren}
                    </ExpandedContainer>
                )}
            </StickyMobileSummaryContainer>
        </>
    );
};

export const StickyMobileSummary = React.memo(StickyMobileSummaryMemo);
