import React, { FC, ReactNode, useMemo } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import {
    ContainerGrid,
    Label,
    LabelAndValue,
    OwnershipInfoCardBenefits,
    SliderContent,
    StyledBookTrialButton,
    StyledCardFootNote,
    StyledCardFootNoteContactPhoneNumberLink,
    StyledCardFootNoteContactPhoneNumberText,
    StyledCheckoutButton,
    StyledContactButton,
    StyledCtaWrapper,
    StyledDisclaimer,
    StyledFooterContent,
    StyledLeasingActions,
    StyledPositionedDisclaimer,
    StyledPositionedPriceSection,
    StyledSellingPoint,
    StyledSellingPointList,
    StyledSliderWrapper,
} from './ownership-card.styled';

import { sharedTypes } from '../../../lib/api/models/umbraco';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { DropdownInputProps } from '../../forms/inputs';
import { Props as SliderProps } from '../../shared/stepped-options-slider/stepped-options-slider.props';
import { OwnershipCardPrice, OwnershipCardPriceProps } from './ownership-card-price.component';
import { SteppedOptionsSlider } from '../../shared/stepped-options-slider/stepped-options-slider.component';

export type OwnershipInfoCardProps = {
    priceVisibilityChanged: (visible: boolean) => void;
    /**
     * Up to 5 selling points to be displayed in the card.
     */
    usps: Array<sharedTypes.LabelAndIcon>;
    price: Omit<OwnershipCardPriceProps, 'priceVisibilityChanged'>;
    disclaimer?: string | ReactNode;
    priceSection?: ReactNode;
    primaryCta: {
        label?: string;
        action?: () => void;
    };
    secondaryCta: {
        label?: string;
        action?: () => void;
    };
    linkCta?: {
        label?: string;
        action?: () => void;
    };
    footerCta: {
        label?: string;
        action?: () => void;
    };
    dropdownProps?: Pick<DropdownInputProps, 'label' | 'onChange' | 'options' | 'value'>;
    hireExtendMonthlyKmDropdownProps?: Pick<DropdownInputProps, 'label' | 'onChange' | 'options' | 'value'>;
    sliderProps?: SliderProps;
    hireExtendMonthlyKmSliderProps?: SliderProps;
    cardFootNote?: string;
    additionalPriceInfo?: React.ReactNode;
    cardFootNoteContactPhoneNumber?: string;
    cardFootNoteContactPhoneLeadingText?: string;
};

export const OwnershipCard: FC<OwnershipInfoCardProps> = (props) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    const { isAFromPrice, selectedProduct } = ProductDetailsStore.useStoreState((state) => state);

    const uspsToShow = useMemo(() => {
        if (!selectedProduct) return [];
        return props.usps
            .filter((x) => {
                if (x.availability === null || x.availability === undefined || x.availability.length === 0) return true;
                if (x.availability === selectedProduct.availability) return true;
                if (props.price.isCompanyCar && x.availability === 'Company') return true;
                return false;
            })
            .splice(0, 5);
    }, [props.price.isCompanyCar, props.usps, selectedProduct]);

    const disclaimer = useMemo(
        () =>
            React.isValidElement(props.disclaimer) ? (
                <StyledPositionedDisclaimer>{props.disclaimer}</StyledPositionedDisclaimer>
            ) : (
                <StyledDisclaimer>{props.disclaimer}</StyledDisclaimer>
            ),
        [props.disclaimer]
    );

    const priceSection = useMemo(
        () => (props.priceSection ? <StyledPositionedPriceSection>{props.priceSection}</StyledPositionedPriceSection> : null),
        [props.priceSection]
    );

    const PricesUI = (
        <OwnershipCardPrice
            {...props.price}
            priceVisibilityChanged={(visible) => props?.priceVisibilityChanged(visible)}
            isAFromPrice={isAFromPrice}
            key={props.price.price}
            additionalPriceInfo={props.additionalPriceInfo}
        />
    );

    const SellingPointsUi = (
        <StyledSellingPointList key={'sellingPoints'} role="list">
            {uspsToShow.map((x, i) => (
                <StyledSellingPoint
                    key={i} // we use index here, because these never change and test data makes the correct scenario annoying
                    role="listitem"
                    iconUrl={x.icon?.src ? MEDIA_URL + x.icon.src : ''}
                    label={x.label}
                    isEmphasized={x.isEmphasized}
                    tooltip={x.tooltip}
                />
            ))}
        </StyledSellingPointList>
    );

    const LeasingPeriodSliderUI =
        props.sliderProps && props.hireExtendMonthlyKmSliderProps ? (
            <StyledSliderWrapper>
                <SliderContent>
                    <LabelAndValue>
                        <Label>{props.sliderProps.label}</Label>
                        <Label bold>{`${props.sliderProps.value?.displayValue}${
                            !props.sliderProps.value?.displayValue.includes('mdr.') ? ' mdr.' : ''
                        }`}</Label>
                    </LabelAndValue>
                    <SteppedOptionsSlider
                        options={props.sliderProps.options}
                        onValueChange={(e) => props.sliderProps?.onChange(e)}
                        value={props.sliderProps.value}
                        defaultValue={props.sliderProps.value}
                    />
                </SliderContent>
                <SliderContent>
                    <LabelAndValue>
                        <Label>{props.hireExtendMonthlyKmSliderProps.label}</Label>
                        <Label bold>{props.hireExtendMonthlyKmSliderProps.value?.displayValue}</Label>
                    </LabelAndValue>
                    <SteppedOptionsSlider
                        options={props.hireExtendMonthlyKmSliderProps.options}
                        onValueChange={(e) => props.hireExtendMonthlyKmSliderProps?.onChange(e)}
                        value={props.hireExtendMonthlyKmSliderProps.value}
                        defaultValue={props.hireExtendMonthlyKmSliderProps.value}
                    />
                </SliderContent>
            </StyledSliderWrapper>
        ) : null;

    const LinkCtaUI = props.linkCta?.action ? (
        <OwnershipInfoCardBenefits key={'benefits'} onClick={() => props.linkCta?.action?.() ?? null} variant="link">
            <p>{props.linkCta.label}</p>
        </OwnershipInfoCardBenefits>
    ) : null;

    const DisclaimerUI = disclaimer ? disclaimer : null;

    const PriceSectionUI = priceSection ? priceSection : null;

    const ActionsUI = (
        <StyledLeasingActions key={'actions'}>
            <StyledCtaWrapper>
                {props.secondaryCta.action ? (
                    <StyledBookTrialButton onClick={() => props.secondaryCta.action?.()}>
                        <span>{props.secondaryCta.label}</span>
                    </StyledBookTrialButton>
                ) : null}
                {props.primaryCta.action ? (
                    <StyledCheckoutButton onClick={() => props.primaryCta.action?.()}>
                        <span>{props.primaryCta.label}</span>
                    </StyledCheckoutButton>
                ) : null}
            </StyledCtaWrapper>
            {props.footerCta.action ? (
                <StyledContactButton onClick={() => props.footerCta.action?.()}>
                    <span>{props.footerCta.label}</span>
                </StyledContactButton>
            ) : null}
            <StyledFooterContent>
                {props.cardFootNoteContactPhoneNumber && props.cardFootNoteContactPhoneLeadingText ? (
                    <StyledCardFootNoteContactPhoneNumberText>
                        {props.cardFootNoteContactPhoneLeadingText}{' '}
                        <StyledCardFootNoteContactPhoneNumberLink href={`tel:${props.cardFootNoteContactPhoneNumber}`}>
                            {props.cardFootNoteContactPhoneNumber}
                        </StyledCardFootNoteContactPhoneNumberLink>
                    </StyledCardFootNoteContactPhoneNumberText>
                ) : null}
                {props.cardFootNote ? <StyledCardFootNote>{props.cardFootNote}</StyledCardFootNote> : null}
            </StyledFooterContent>
        </StyledLeasingActions>
    );

    // Order UI elements based on mobile or desktop
    const OrderedElements = isMobile
        ? [PricesUI, SellingPointsUi, LinkCtaUI, LeasingPeriodSliderUI, PriceSectionUI, DisclaimerUI, ActionsUI]
        : [SellingPointsUi, LeasingPeriodSliderUI, PricesUI, LinkCtaUI, PriceSectionUI, DisclaimerUI, ActionsUI];

    return <ContainerGrid>{OrderedElements}</ContainerGrid>;
};
