import React, { VFC } from 'react';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { VehicleFilterQueryParams, appendParamsToUrl, generatePdpUrl } from '../../../utils/helpers';
import { VehicleProductCardCarousel } from '../../vehicle/vehicle-product-card-carousel/vehicle-product-card-carousel.component';
import { UsedCarProductRibbonWrapper } from './used-car-product-ribbon.styled';
import { useProductRibbon } from '../hooks/use-product-ribbon';
import Link from 'next/link';
import { VehicleProductListCard } from '../../vehicle/vehicle-product-list/vehicle-product-list-card';
import { FilterConfigurationKey } from '../../../constants/site-consts';

type TestCarProductRibbonProps = {
    header: string;
    description: string;
    seeAllLink: sharedTypes.CtaUrl;
    usedCarsFilterConfigurationId?: string;
    currentDealershipId?: string;
};

const UsedCarProductRibbonMemo: VFC<TestCarProductRibbonProps> = ({
    header,
    description,
    seeAllLink,
    usedCarsFilterConfigurationId,
    currentDealershipId,
}) => {
    const { vehicles: usedVehicles } = useProductRibbon(
        usedCarsFilterConfigurationId,
        {
            from: 0,
            take: 1000,
            filters: [FilterConfigurationKey.availability_used, `${FilterConfigurationKey.locationId}${currentDealershipId}`],
        },
        'Car'
    );

    if (!usedVehicles || usedVehicles.length === 0 || !currentDealershipId) {
        return null;
    }

    const queryParams: Array<VehicleFilterQueryParams> = [
        { key: 'filter', value: FilterConfigurationKey.availability_used },
        { key: 'filter', value: `${FilterConfigurationKey.locationId}${currentDealershipId}` },
    ];

    const linkUrl = appendParamsToUrl(seeAllLink.url, queryParams);

    return (
        <UsedCarProductRibbonWrapper>
            <VehicleProductCardCarousel
                header={header}
                text={description}
                link={{
                    ...seeAllLink,
                    url: linkUrl,
                }}
            >
                {usedVehicles.map((product, index) => {
                    return (
                        <Link key={`${product.id}-${index}`} href={generatePdpUrl(product, false, 'Køb', 'Car')}>
                            <a href={generatePdpUrl(product)} style={{ textDecoration: 'none' }}>
                                <VehicleProductListCard
                                    productCard={product}
                                    className="keen-slider__slide"
                                    allowCarousel={false}
                                    priceType={'Purchase'}
                                />
                            </a>
                        </Link>
                    );
                })}
            </VehicleProductCardCarousel>
        </UsedCarProductRibbonWrapper>
    );
};

export const UsedCarProductRibbon = React.memo(UsedCarProductRibbonMemo);
