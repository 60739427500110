import { useMemo, useState } from 'react';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { DateStyle, formatDate } from '../../../utils/helpers';
import { useSantander } from './use-santander';
import { ProductDetailsPage } from '../../../lib/api/models/umbraco';
import { PriceByOwnership } from './price-by-ownership.types';
import { CarFinancing } from '../../../lib/api/models/hessel-api/hire';

type UsePriceByOwnershipReturnType = {
    priceInformation: PriceByOwnership;
    santanderFinancing: CarFinancing | undefined;
    santanderError: unknown;
    santanderIsLoading: boolean;
    santanderIsValidating: boolean;
};

const Including_Vat_And_Expenses_Text = 'Inkl. moms og afgifter';
const Including_Vat_Text = 'Inkl. moms';
const Excluding_Vat_Text = 'Ekskl. moms';
const Excluding_Vat_Including_Expenses = 'Ekskl. moms men inkl. afgifter';
const USED_VAN_VAT_TEXT = 'Ekskl. moms men inkl. leveringsomkostninger';
const USED_CAR_VAT_TEXT = 'Inkl. lev. omk.';

export const usePriceByOwnership = (page: ProductDetailsPage): UsePriceByOwnershipReturnType => {
    const {
        selectedProduct: product,
        selectedTab: selectedOwnershipTab,
        computedCashPrice: cashPrice,
        computedMonthlyPrice: monthlyPrice,
        computedDownPayment: downPayment,
        financialPurchaseDownPaymentPercentage,
        selectedColor,
        extraEquipmentPriceSum,
        extraEquipmentPackagePriceSum,
        optionalEquipmentPriceSum,
    } = ProductDetailsStore.useStoreState((state) => state);
    const [santanderPaymentPerMonth, setSantanderPaymentPerMonth] = useState<number>();
    const [santanderDownPayment, setSantanderDownPayment] = useState<number>();

    if (!product) {
        throw new Error('No product selected');
    }

    const {
        santanderFinancing,
        error: santanderError,
        isLoading: santanderIsLoading,
        isValidating: santanderIsValidating,
    } = useSantander(
        {
            DownPayment: Math.ceil(cashPrice * (financialPurchaseDownPaymentPercentage / 100)),
            FirstRegistrationDate: product.specifications.firstMonthAndYearOfRegistration,
            Mileage: product.specifications.odometerReadingInKm ?? 0,
            ModelYear: +formatDate(new Date(), DateStyle.year_only),
            ObjectPrice: `${cashPrice}`,
            PaymentTerms: product.purchaseTypes.financing.durationMonths,
            FuelType: product.fuelTypeAbbreviation,
            Brand: product.brand,
        },
        selectedOwnershipTab === 'Van Finansiering' || selectedOwnershipTab === 'Car Finansiering'
    );

    useMemo(() => {
        if (santanderIsLoading || santanderIsValidating || !santanderFinancing?.downPayment || !santanderFinancing?.paymentPerMonth) {
            return;
        }

        if (santanderError) {
            setSantanderDownPayment(undefined);
            setSantanderPaymentPerMonth(undefined);
            return;
        }

        if (santanderFinancing?.paymentPerMonth !== santanderPaymentPerMonth) {
            setSantanderPaymentPerMonth(santanderFinancing?.paymentPerMonth);
        }
        if (santanderFinancing?.downPayment !== santanderDownPayment) {
            setSantanderDownPayment(santanderFinancing?.downPayment);
        }
    }, [
        santanderFinancing?.downPayment,
        santanderFinancing?.paymentPerMonth,
        santanderError,
        santanderIsLoading,
        santanderIsValidating,
        setSantanderDownPayment,
        setSantanderPaymentPerMonth,
        santanderDownPayment,
        santanderPaymentPerMonth,
    ]);

    const priceInformation = useMemo(() => {
        switch (selectedOwnershipTab) {
            /**
             * CARS
             */

            /**
             * New decision:
             * https://jira.impact.dk/browse/EJH-2904
             * Personbiler + firmabiler (kontant og finansiering): Inkl. moms og afgifter
             * Personbiler (HiRE + privatleasing): Inkl. moms
             */

            // Leasing toggle selected
            case 'Car HiRE':
                return {
                    price: monthlyPrice,
                    monthly: true,
                    downPayment: downPayment,
                    vatIncluded: true,
                    priceTextOverride: Including_Vat_Text,
                };
            case 'Car Privat Leasing':
                return {
                    price: monthlyPrice,
                    monthly: true,
                    downPayment: downPayment,
                    overrideText: !monthlyPrice ? page?.contactForPriceLabel : undefined,
                    priceTextOverride: Including_Vat_Text,
                };

            // Køb toggle selected
            case 'Car Kontant': {
                const PriceOverrideText = product.availability !== 'Used' ? Including_Vat_And_Expenses_Text : USED_CAR_VAT_TEXT;

                return {
                    price: cashPrice,
                    priceTextOverride: PriceOverrideText,
                };
            }
            case 'Beskatningsgrundlag': {
                const PriceOverrideText = product.availability !== 'Used' ? Including_Vat_And_Expenses_Text : USED_CAR_VAT_TEXT;
                return {
                    price:
                        product.purchaseTypes.solutionTaxation?.basePrice +
                        extraEquipmentPriceSum +
                        extraEquipmentPackagePriceSum +
                        optionalEquipmentPriceSum +
                        (selectedColor?.cashPrice ?? 0),
                    monthly: false,
                    priceTextOverride: PriceOverrideText,
                };
            }
            case 'Car Finansiering': {
                return {
                    price: santanderPaymentPerMonth ? santanderPaymentPerMonth : product.purchaseTypes.financing?.baseMonthlyPrice,
                    monthly: true,
                    downPayment: santanderDownPayment ? santanderDownPayment : product.purchaseTypes.financing?.downPayment,
                    priceTextOverride: product.availability !== 'Used' ? Including_Vat_And_Expenses_Text : USED_CAR_VAT_TEXT,
                };
            }

            /**
             * VANS
             * https://jira.impact.dk/browse/EJH-2688
             * "Priser er eksl. moms." for all van purchase types
             */

            /**
             * New decision:
             * https://jira.impact.dk/browse/EJH-2904
             * Varebiler (kontant og finansiering) : Ekskl. moms men inkl. afgifter
             * Varebiler (operationel/finansiel): Ekskl. moms
             */
            // Leasing toggle selected
            case 'Operationel Leasing':
                return {
                    price: monthlyPrice,
                    monthly: true,
                    downPayment: downPayment,
                    vatIncluded: true,
                    priceTextOverride: Excluding_Vat_Text,
                };

            case 'Finansiel Leasing':
                return {
                    price: monthlyPrice,
                    monthly: true,
                    downPayment: downPayment,
                    vatIncluded: true,
                    priceTextOverride: Excluding_Vat_Text,
                };

            // Køb toggle selected
            case 'Van Kontant':
                return {
                    price: cashPrice,
                    vatIncluded: true,
                    priceTextOverride: product.availability !== 'Used' ? Excluding_Vat_Including_Expenses : USED_VAN_VAT_TEXT,
                };
            case 'Van Finansiering': {
                return {
                    price: santanderPaymentPerMonth ? santanderPaymentPerMonth : product.purchaseTypes.financing?.baseMonthlyPrice,
                    monthly: true,
                    downPayment: santanderDownPayment ? santanderDownPayment : product.purchaseTypes.financing?.downPayment,
                    vatIncluded: true,
                    priceTextOverride: product.availability !== 'Used' ? Excluding_Vat_Including_Expenses : USED_VAN_VAT_TEXT,
                };
            }

            case 'No Value':
                throw new Error('No value for selectedOwnershipTab');

            default: {
                const exhaustiveCheck: never = selectedOwnershipTab;
                return exhaustiveCheck;
            }
        }
    }, [
        cashPrice,
        downPayment,
        extraEquipmentPackagePriceSum,
        extraEquipmentPriceSum,
        monthlyPrice,
        optionalEquipmentPriceSum,
        page?.contactForPriceLabel,
        product.availability,
        product.purchaseTypes.financing?.baseMonthlyPrice,
        product.purchaseTypes.financing?.downPayment,
        product.purchaseTypes.solutionTaxation?.basePrice,
        santanderDownPayment,
        santanderPaymentPerMonth,
        selectedColor?.cashPrice,
        selectedOwnershipTab,
    ]);

    return {
        priceInformation,
        santanderFinancing,
        santanderError,
        santanderIsLoading,
        santanderIsValidating,
    };
};
