import styled from 'styled-components';
import { Button } from '../../../shared';

export const StyledModalHeader = styled.p`
    font-size: 26px;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fontFamily.primary};
    margin-bottom: 10px;
`;

export const StyledModalSubHeader = styled.p`
    font-size: 14px;
    line-height: 21px;
`;

export const StyledLeasingInformationTable = styled.div`
    margin-top: 24px;
`;

export const StyledLeasingInformationTableRow = styled.div`
    padding: 12px;
    padding-right: 24px;
    display: flex;
    justify-content: space-between;

    :nth-child(odd) {
        background-color: #f1f1f2;
    }

    strong {
        font-weight: 600;
    }
`;

export const StyledTextWithDisclaimer = styled.span`
    position: relative;
    &::after {
        content: '*';
        position: absolute;
        margin-left: 4px;
        color: ${({ theme }) => theme.palette.error.main};
    }
`;
export const StyledPriceInfoText = styled.p`
    margin-top: 4px;
    margin-left: 12px;
    color: #807f80;
    font-size: 12px;
`;

export const StyledDivider = styled.hr`
    margin: 32px 0;
    border-top: 1px solid #e2e2e4;
`;

export const StyledFormHeader = styled.p`
    font-weight: 600;
    font-size: 16px;
`;

export const StyledInputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
`;

export const StyledCheckboxLabel = styled.div`
    font-size: 14px;
    font-weight: 400;
`;

export const StyledCta = styled(Button)`
    margin-top: 32px;
`;
