import { useMemo, VFC } from 'react';
import { ProductDetailsPage } from '../../../../lib/api/models/umbraco';
import { PdpCarFinanceSetup, PdpVanFinanceSetup } from '../../../../lib/api/models/umbraco/product-details';
import { VanLeasingOwnershipFinancialTab, VanLeasingOwnershipOperationalTab } from '../../../../lib/api/models/umbraco/product-types/vans.types';
import { CarLeasingOwnershipFinancialTab, CarLeasingOwnershipOperationalTab } from '../../../../lib/api/models/umbraco/product-types/cars.types';
import { hesselViewModels } from '../../../../lib/view-models';
import { formatDistance } from '../../../../utils/helpers/distance.helper';
import { TabbedCard } from '../../../shared/tabbed-card/tabbed-card.component';
import { OwnershipCard } from '../../ownership-card/ownership-card.component';
import { OpenDialog, usePdpModals } from '../../product-details-page/product-details-page.context';

import {
    IncludingDeliveryAndComplianceTextWrapper,
    PopularityBadge,
    PopularityBadgeText,
    PositionedComplianceText,
    StyledIncludingDeliveryText,
    StyledTaxationPriceText,
} from './company-leasing-tabs.component.styled';
import { PriceByOwnership } from '../../hooks/price-by-ownership.types';
import {
    getLeasingTermsUsps,
    getVanFinancialLeasingDisclaimer,
    getVanOperationalLeasingDisclaimer,
} from '../../../../utils/helpers/price-by-ownership.helper';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { Abbreviations } from '../../../../constants/units-and-abbreviations';
import { LeasingTerms } from '../../../../lib/api/models/hessel-api/vehicle';
import { useVanCtaConfig } from '../../vans/use-van-cta-config';
import { formatPrice } from '../../../../utils/helpers';
import { useCarCtaConfig } from '../../cars/use-car-cta-config';

export type CompanyLeasingTabsProps = {
    page: ProductDetailsPage;
    product: hesselViewModels.ProductDetails;
    selectedOwnershipTab: hesselViewModels.OwnershipTab;
    setSelectedOwnershipTab: (tab: hesselViewModels.OwnershipTab) => void;
    financialSetup: Array<PdpVanFinanceSetup> | Array<PdpCarFinanceSetup>;
    priceVisibilityChanged: (visible: boolean) => void;
    priceInformation: PriceByOwnership;
    equipmentSum?: number;
};

export const CompanyLeasingTabs: VFC<CompanyLeasingTabsProps> = ({
    page,
    product,
    selectedOwnershipTab,
    setSelectedOwnershipTab,
    financialSetup,
    priceVisibilityChanged,
    priceInformation,
    equipmentSum,
}) => {
    const { openDialog } = usePdpModals();
    const vanCtaConfig = useVanCtaConfig(page);
    const carCtaConfig = useCarCtaConfig(page, selectedOwnershipTab, product, true);
    const CtaConfig = useMemo(() => {
        return product.vehicleType === 'Car' ? carCtaConfig : vanCtaConfig;
    }, [carCtaConfig, product.vehicleType, vanCtaConfig]);

    const { isAFromPrice } = ProductDetailsStore.useStoreState((state) => state);

    const operationalLeasingCms =
        product.vehicleType === 'Van'
            ? ((financialSetup as PdpVanFinanceSetup[]).find(
                  (x) => x.alias === 'vanLeasingOwnershipOperationalTab'
              ) as VanLeasingOwnershipOperationalTab)
            : ((financialSetup as PdpCarFinanceSetup[])?.find(
                  (x) => x.alias === 'carLeasingOwnershipOperationalTab'
              ) as CarLeasingOwnershipOperationalTab);

    const operationalLeasingTab =
        operationalLeasingCms && product.purchaseTypes.operationalLeasing.show
            ? {
                  id: 'Operationel Leasing',
                  label: operationalLeasingCms?.tabName ?? '',
                  description: `${product.purchaseTypes.operationalLeasing.durationMonths} mdr.`,
                  badge:
                      product.brand === 'Mercedes-Benz' && product.vehicleType === 'Van' ? (
                          <PopularityBadge>
                              <PopularityBadgeText>Populær</PopularityBadgeText>
                          </PopularityBadge>
                      ) : null,
                  onClick: () => setSelectedOwnershipTab('Operationel Leasing'),
                  content: (
                      <OwnershipCard
                          priceVisibilityChanged={priceVisibilityChanged}
                          primaryCta={{
                              label: CtaConfig[product.availability]?.labels.primary,
                              action: CtaConfig[product.availability]?.actions.primary,
                          }}
                          secondaryCta={{
                              label: CtaConfig[product.availability]?.labels.secondary,
                              action: CtaConfig[product.availability]?.actions.secondary,
                          }}
                          footerCta={{
                              label: CtaConfig[product.availability]?.labels.footerLink,
                              action: CtaConfig[product.availability]?.actions.footerLink,
                          }}
                          usps={[
                              ...(product.purchaseTypes.operationalLeasing.kilometersPerYear
                                  ? [
                                        {
                                            label: `${formatDistance(
                                                (product.purchaseTypes.operationalLeasing.kilometersPerYear /
                                                    product.purchaseTypes.operationalLeasing.durationMonths) *
                                                    12
                                            )} ${Abbreviations.KM_SLASH_AAR}.`,
                                            icon: operationalLeasingCms.mileageIcon,
                                            isEmphasized: true,
                                        },
                                    ]
                                  : []),
                              ...getLeasingTermsUsps(
                                  product.purchaseTypes.operationalLeasing.leasingTerms as LeasingTerms,
                                  operationalLeasingCms.mileageIcon
                              ),
                              ...(operationalLeasingCms?.usps ?? []),
                          ]}
                          linkCta={
                              product.purchaseTypes.financialLeasing.show && product.purchaseTypes.operationalLeasing.show
                                  ? {
                                        label: operationalLeasingCms?.ctaText,
                                        action: () => openDialog({ dialog: OpenDialog.CompareVansLeasingTypes }),
                                    }
                                  : undefined
                          }
                          price={priceInformation}
                          disclaimer={
                              <IncludingDeliveryAndComplianceTextWrapper>
                                  {product.vehicleType === 'Car' && (
                                      <>
                                          {product.availability !== 'Engros' && page.carIncludingDeliveryText?.length ? (
                                              <StyledIncludingDeliveryText>{page.carIncludingDeliveryText}</StyledIncludingDeliveryText>
                                          ) : null}
                                      </>
                                  )}
                                  {product.vehicleType === 'Van' && (
                                      <>
                                          {product.availability !== 'Engros' && page.vanIncludingDeliveryText?.length ? (
                                              <StyledIncludingDeliveryText>{page.vanIncludingDeliveryText}</StyledIncludingDeliveryText>
                                          ) : null}

                                          {product.availability === 'Engros' && page.vanIncludingDeliveryTextEngros?.length > 0 ? (
                                              <StyledIncludingDeliveryText>{page.vanIncludingDeliveryTextEngros}</StyledIncludingDeliveryText>
                                          ) : null}
                                      </>
                                  )}

                                  <PositionedComplianceText
                                      text={getVanOperationalLeasingDisclaimer(
                                          product.specifications.consumption,
                                          product.specifications.co2Emission,
                                          product.energyRating,
                                          priceInformation.price,
                                          product.purchaseTypes.operationalLeasing.downPayment,
                                          product.purchaseTypes.operationalLeasing.durationMonths,
                                          product.purchaseTypes.operationalLeasing.kilometersPerYear,
                                          product.purchaseTypes.operationalLeasing.establishmentFee,
                                          product.purchaseTypes.operationalLeasing.pantOwnerDeclaration,
                                          +product.specifications.greenOwnershipFee,
                                          isAFromPrice,
                                          product.purchaseTypes.operationalLeasing.leasingTerms as LeasingTerms,
                                          product.purchaseTypes.operationalLeasing.pricePerOvertakingKilometers,
                                          priceInformation.price * product.purchaseTypes.operationalLeasing.durationMonths +
                                              product.purchaseTypes.operationalLeasing.downPayment +
                                              (product.purchaseTypes.operationalLeasing.establishmentFee ?? 0)
                                      )}
                                  />
                              </IncludingDeliveryAndComplianceTextWrapper>
                          }
                          additionalPriceInfo={
                              product.vehicleType === 'Car' ? (
                                  <StyledTaxationPriceText>
                                      Beskatningsgrundlag
                                      <strong style={{ color: '#0B0B0B', fontWeight: 500 }}>{`${formatPrice(
                                          product.purchaseTypes.operationalLeasing.taxationPrice + (equipmentSum ?? 0)
                                      )} ${Abbreviations.KR}`}</strong>
                                  </StyledTaxationPriceText>
                              ) : null
                          }
                      />
                  ),
              }
            : null;

    const financialLeasingCms =
        product.vehicleType === 'Van'
            ? ((financialSetup as PdpVanFinanceSetup[]).find((x) => x.alias === 'vanLeasingOwnershipFinancialTab') as VanLeasingOwnershipFinancialTab)
            : ((financialSetup as PdpCarFinanceSetup[]).find(
                  (x) => x.alias === 'carLeasingOwnershipFinancialTab'
              ) as CarLeasingOwnershipFinancialTab);
    const financialLeasingTab =
        financialLeasingCms && product.purchaseTypes.financialLeasing.show
            ? {
                  id: 'Finansiel Leasing',
                  label: financialLeasingCms?.tabName ?? '',
                  description: `${product.purchaseTypes.financialLeasing.durationMonths} mdr.`,
                  badge:
                      (product.brand === 'Renault' || product.brand === 'Ford' || product.brand === 'Dacia') && product.vehicleType === 'Van' ? (
                          <PopularityBadge>
                              <PopularityBadgeText>Populær</PopularityBadgeText>
                          </PopularityBadge>
                      ) : null,
                  onClick: () => setSelectedOwnershipTab('Finansiel Leasing'),
                  content: (
                      <OwnershipCard
                          priceVisibilityChanged={priceVisibilityChanged}
                          primaryCta={{
                              label: CtaConfig[product.availability]?.labels.primary,
                              action: CtaConfig[product.availability]?.actions.primary,
                          }}
                          secondaryCta={{
                              label: CtaConfig[product.availability]?.labels.secondary,
                              action: CtaConfig[product.availability]?.actions.secondary,
                          }}
                          footerCta={{
                              label: CtaConfig[product.availability]?.labels.footerLink,
                              action: CtaConfig[product.availability]?.actions.footerLink,
                          }}
                          usps={[
                              ...(product.purchaseTypes.financialLeasing.kilometersPerYear
                                  ? [
                                        {
                                            label: `${formatDistance(
                                                (product.purchaseTypes.financialLeasing.kilometersPerYear /
                                                    product.purchaseTypes.financialLeasing.durationMonths) *
                                                    12
                                            )} ${Abbreviations.KM_SLASH_AAR}.`,
                                            icon: financialLeasingCms.mileageIcon,
                                            isEmphasized: true,
                                        },
                                    ]
                                  : []),
                              ...getLeasingTermsUsps(
                                  product.purchaseTypes.financialLeasing.leasingTerms as LeasingTerms,
                                  financialLeasingCms.mileageIcon
                              ),
                              ...(product.purchaseTypes.financialLeasing.residualValue
                                  ? [
                                        {
                                            label: `Restværdi ${formatPrice(product.purchaseTypes.financialLeasing.residualValue)} ${
                                                Abbreviations.KR
                                            }`,
                                            icon: financialLeasingCms.mileageIcon,
                                            // tooltip:
                                            //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                        },
                                    ]
                                  : []),
                              ...(financialLeasingCms?.usps ?? []),
                          ]}
                          linkCta={
                              product.purchaseTypes.financialLeasing.show && product.purchaseTypes.operationalLeasing.show
                                  ? { label: financialLeasingCms?.ctaText, action: () => openDialog({ dialog: OpenDialog.CompareVansLeasingTypes }) }
                                  : undefined
                          }
                          price={priceInformation}
                          disclaimer={
                              <IncludingDeliveryAndComplianceTextWrapper>
                                  {product.vehicleType === 'Car' && (
                                      <>
                                          {product.availability !== 'Engros' && page.carIncludingDeliveryText?.length > 0 ? (
                                              <StyledIncludingDeliveryText>{page.carIncludingDeliveryText}</StyledIncludingDeliveryText>
                                          ) : null}
                                      </>
                                  )}
                                  {product.vehicleType === 'Van' && (
                                      <>
                                          {product.availability !== 'Engros' && page.carIncludingDeliveryText?.length > 0 ? (
                                              <StyledIncludingDeliveryText>{page.carIncludingDeliveryText}</StyledIncludingDeliveryText>
                                          ) : null}

                                          {product.availability === 'Engros' && page.vanIncludingDeliveryTextEngros?.length > 0 ? (
                                              <StyledIncludingDeliveryText>{page.vanIncludingDeliveryTextEngros}</StyledIncludingDeliveryText>
                                          ) : null}
                                      </>
                                  )}

                                  <PositionedComplianceText
                                      text={getVanFinancialLeasingDisclaimer(
                                          product.specifications.consumption,
                                          product.specifications.co2Emission,
                                          product.energyRating,
                                          priceInformation.price,
                                          product.purchaseTypes.financialLeasing.downPayment,
                                          product.purchaseTypes.financialLeasing.durationMonths,
                                          product.purchaseTypes.financialLeasing.kilometersPerYear,
                                          +product.specifications.greenOwnershipFee,
                                          product.purchaseTypes.financialLeasing.residualValue,
                                          isAFromPrice,
                                          product.purchaseTypes.financialLeasing.leasingTerms as LeasingTerms,
                                          product.purchaseTypes.financialLeasing.pricePerOvertakingKilometers,
                                          priceInformation.price * product.purchaseTypes.financialLeasing.durationMonths +
                                              product.purchaseTypes.financialLeasing.downPayment +
                                              (product.purchaseTypes.financialLeasing.establishmentFee ?? 0)
                                      )}
                                  />
                              </IncludingDeliveryAndComplianceTextWrapper>
                          }
                          additionalPriceInfo={
                              product.vehicleType === 'Car' ? (
                                  <div>
                                      <StyledTaxationPriceText>
                                          Beskatningsgrundlag
                                          <strong style={{ color: '#0B0B0B', fontWeight: 500 }}>{`${formatPrice(
                                              product.purchaseTypes.financialLeasing.taxationPrice + (equipmentSum ?? 0)
                                          )} ${Abbreviations.KR}`}</strong>
                                      </StyledTaxationPriceText>
                                  </div>
                              ) : null
                          }
                      />
                  ),
              }
            : null;

    const leasingOwnershipTabs = [];
    const contactForPriceTab = {
        id: 'Finansiel Leasing',
        label: financialLeasingCms?.tabName ?? '',
        description: `Variabel periode`,
        badge: null,
        onClick: () => setSelectedOwnershipTab('Finansiel Leasing'),
    };

    if (operationalLeasingTab) {
        leasingOwnershipTabs.push(operationalLeasingTab);
    } else if (product.vehicleType === 'Car') {
        leasingOwnershipTabs.push({
            ...contactForPriceTab,
            id: 'Operationel Leasing',
            label: operationalLeasingCms?.tabName ?? '',
            onClick: () => setSelectedOwnershipTab('Operationel Leasing'),
            content: (
                <OwnershipCard
                    priceVisibilityChanged={priceVisibilityChanged}
                    primaryCta={{
                        label: 'Kontakt os',
                        action: () => openDialog({ dialog: OpenDialog.ContactHessel }),
                    }}
                    secondaryCta={{
                        label: 'Book prøvetur',
                        action: () => openDialog({ dialog: OpenDialog.BookTrial, sidebarOpened: 'Book Prøvetur' }),
                    }}
                    footerCta={{}}
                    usps={[
                        ...getLeasingTermsUsps(
                            product.purchaseTypes.operationalLeasing.leasingTerms as LeasingTerms,
                            operationalLeasingCms.mileageIcon
                        ),
                        ...(operationalLeasingCms?.usps ?? []),
                    ]}
                    price={{
                        price: 0,
                        priceTextOverride: 'Så får du et uforpligtende tilbud',
                    }}
                    additionalPriceInfo={
                        <StyledTaxationPriceText>
                            Beskatningsgrundlag
                            <strong style={{ color: '#0B0B0B', fontWeight: 500 }}>{`${formatPrice(
                                product.purchaseTypes.operationalLeasing.taxationPrice + (equipmentSum ?? 0)
                            )} ${Abbreviations.KR}`}</strong>
                        </StyledTaxationPriceText>
                    }
                />
            ),
        });
    }
    if (financialLeasingTab) {
        leasingOwnershipTabs.push(financialLeasingTab);
    } else if (product.vehicleType === 'Car') {
        leasingOwnershipTabs.push({
            ...contactForPriceTab,
            content: (
                <OwnershipCard
                    priceVisibilityChanged={priceVisibilityChanged}
                    primaryCta={{
                        label: 'Kontakt os',
                        action: () => openDialog({ dialog: OpenDialog.ContactHessel }),
                    }}
                    secondaryCta={{
                        label: 'Book prøvetur',
                        action: () => openDialog({ dialog: OpenDialog.BookTrial, sidebarOpened: 'Book Prøvetur' }),
                    }}
                    footerCta={{}}
                    usps={[
                        ...getLeasingTermsUsps(product.purchaseTypes.financialLeasing.leasingTerms as LeasingTerms, financialLeasingCms.mileageIcon),
                        ...(financialLeasingCms?.usps ?? []),
                    ]}
                    price={{
                        price: 0,
                        priceTextOverride: 'Så får du et uforpligtende tilbud',
                    }}
                    additionalPriceInfo={
                        <StyledTaxationPriceText>
                            Beskatningsgrundlag
                            <strong style={{ color: '#0B0B0B', fontWeight: 500 }}>{`${formatPrice(
                                product.purchaseTypes.financialLeasing.taxationPrice + (equipmentSum ?? 0)
                            )} ${Abbreviations.KR}`}</strong>
                        </StyledTaxationPriceText>
                    }
                />
            ),
        });
    }

    const tabs = leasingOwnershipTabs;

    return (
        <TabbedCard
            controlled
            activeTabId={selectedOwnershipTab}
            onAccesibilityTabSelected={(id) => setSelectedOwnershipTab(id as hesselViewModels.OwnershipTab)}
            tabs={tabs}
        />
    );
};
